.popover-list__options{
    max-height: 100px;
    margin: 0;

    display: flex;
    flex-direction: column;
    overflow-y: auto;

}

.popover-list__input-wrap{
    padding: 0 10px 10px;
}

.popover-list__options li{
    padding: 5px 5px 5px 10px;
}

.popover-list__options li:first-child{
    padding-top: 0;
}

.popover-list__item-selected{
    color:var(--main-text-color);
    background-color: var(--selected-menu-item);
}

.popover-tooltip{
    padding: 0 5px;
}