.roadmap-item-priority {
    height: 100%;
    
    background-color: var(--low-priority);
    border-radius: 5px 0 0 5px;
}

.roadmap-item-priority {
    background-color: var(--low-priority);
    border-radius: 5px 0 0 5px;
}

.roadmap-item-priority-low{
    background-color: var(--low-priority);
}

.roadmap-item-priority-medium{
    background-color: var(--medium-priority);
}
.roadmap-item-priority-important{
    background-color: var(--important-priority);
}
.roadmap-item-priority-critical{
    background-color: var(--critical-priority);
}