.roadmap-item-calendar-data{
    display: grid;
    /* grid-template-columns: 146px 147px 146px 147px; */
}

.roadmap-item-calendar-quarter:nth-child(2){
    border-left: 2px solid var(--field-border);
    border-right: 2px solid var(--field-border);
}

.roadmap-item-calendar-quarter:nth-child(3){
    border-right: 2px solid var(--field-border);
}


.roadmap-item-calendar-quarter{
    display: grid;
    grid-template-columns: repeat(3,1fr);
    align-items: center;
}

.roadmap-item-calendar-month{
    width: 100%;
    height: 100%;

    display: flex;
    position: relative;
    align-items: center;
}

.roadmap-item-calendar-month .custom-popover{
    width: 100%;
    height: 100%;
}

.roadmap-item-calendar-month:nth-child(2){
    border-left: 2px solid var(--field-border);
    border-right: 2px solid var(--field-border);
}

.roadmap-item-calendar-month--value{
    width: 100%;
    height: 20px;
    position: relative;
}

.roadmap-item-today-line{
    width: 1px;
    height: 240%;
    
    position: absolute;
    top: -50%;
    bottom: 0;

    background-color: var(--orange);
}

.roadmap-item:first-child .roadmap-item-today-line::before{
    content: '';

    width: 6px;
    height: 6px;

    border-radius: 50%;
    background-color: var(--orange);

    position: absolute;
    top: 0;
    left: 0;

    transform: translateX(-2.5px);
}

.roadmap-item:first-child .roadmap-item-today-line{
    height: 100%;

    left: 50%;
    top: 0;
    bottom: auto;
}
.roadmap-item:last-child .roadmap-item-today-line{
    height: 100%;
    
    top: 0;
    bottom: 0;
    
    overflow: hidden;
}



.roadmap-item-calendar-month-popover-text{
    color:var(--white-color);
}

.roadmap-item-calendar-month-interval{
    height: 100%;

    position: absolute;
    top: 0;
    bottom: 0;
    /* left:37%;
    right: 10%; */
}

.roadmap-item-calendar-month-interval-low{
    background-color: var(--low-priority);
    opacity: .4;
}

.roadmap-item-calendar-month-interval-medium{
    background-color: var(--medium-priority);
    opacity: .4;
}

.roadmap-item-calendar-month-interval-important{
    background-color: var(--important-priority);
    opacity: .4;
}

.roadmap-item-calendar-month-interval-critical{
    background-color: var(--critical-priority);
    opacity: .4;
}

/*временно*/

