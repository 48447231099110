.terms-policy-paragraph{
    font-size: 18px !important;
    font-weight: 600;
    margin: 10px 0;
}

.terms-policy-paragraph-title{
    text-align: center;
    font-size: 20px !important;
    font-weight: 600;
    margin: 10px 0;
}

.terms-policy-paragraph--highliter{
    display: inline-block;
    padding: 0 4px;
    background: lightgoldenrodyellow;
}

.terms-policy-table{
    margin:5px 0;
    border:1px solid var(--main-text-color)
}

.terms-policy-table-row{
    display: grid;
    grid-template-columns: 170px 1fr;
    border-bottom: 1px solid var(--main-text-color);
}

.terms-policy-table-row:last-child{
    border-bottom: none;
}

.terms-policy-table-row > div{
    padding: 10px;
}

.terms-policy-table-row > div:first-child{
    border-right: 1px solid var(--main-text-color);
}

.terms-policy-table-row > div:last-child{
    background: lightgoldenrodyellow;
}