.table-top{
    margin-bottom: 5px;
    padding: 5px 0;
    display: grid;
    grid-auto-flow: column;
    border-radius: var(--border-radius)
}

.fileBlock__top > div:first-child{
    width: 18px;
    justify-self: center;
}

.fileBlock__top > svg{
    width: 20px;
}

.fileBlock__top svg{
    width: 21px;
    height: 21px;
}

.fileBlock__topContent{
    display: flex;
    align-items: center;
    gap: 5px;
}