.coreStudyInput {
    text-align: center;

    display: flex;
    flex-direction: column;
}

.coreStudyInput__select {
    width: 100%;
    background: #e9eff3;
    border-radius: var(--border-radius);
    border: 1px solid transparent;
}

.selectError {
    width: 100%;

    background: #e9eff3;
    border-radius: var(--border-radius);

    border: 1px solid red;
}

.coreStudyInput
    .ant-select:not(.ant-select-customize-input)
    .ant-select-selector {
    border: none;
}
/*.selectError .ant-select-selector:hover{*/
/*    border: 1px solid red;*/
/*}*/

.coreStudyInput
    .ant-select:not(.ant-select-customize-input)
    .ant-select-selector {
    padding: 0;
    background: #e9eff3;
    border-radius: var(--border-radius);
    color: var(--main-text-color);
    height: 26px;
}

.selectError .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border: 1px solid red;
}

.coreStudyInput__label {
    font-size: 12px;

    color: var(--main-text-color);
}
.coreStudyInput__input {
    outline: none;
    width: 100%;
    border: 1px solid transparent;
    border-radius: var(--border-radius);
}

.inputError .coreStudyInput__select {
    border-color: red;
}

.coreStudyInput__numeric {
    height: 30px;
    font-size: 16px;
    border: 1px solid transparent;
}

.inputError {
    border-color: red;
}

.ant-select-dropdown {
    min-width: 150px;
}

.addValueSelect__bottom {
    padding: 0 5px;
    display: flex;
    align-items: center;
    gap: 2px;
}

.addValueSelect__btn {
    width: 26px;
    height: 26px;
    display: flex;
    align-items: center;
    justify-content: center;
    outline: none;
    border-style: none;
    background: transparent;
    cursor: pointer;
}

.addValueSelect__btn svg {
    width: 26px;
    height: 26px;
}

.coreStudyInput__numeric {
    height: 26px !important;
}
