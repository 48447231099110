.signBlock {
    width: 100%;
    min-height: 800px;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    background-image: url("./../image_files/laboratoryImg.jpg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.signBlock .langPanel-btn, .signForm__agreementContent{
    color:var(--white-color);
}

.signBlock__top {
    height: 111px;
    padding: 0 10px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    position: relative;
}

.signBlock__top--name{
    max-width: 560px;
    position: absolute;
    left: 50%;
    top: 14px;
    transform: translateX(-50%);

    font-size: 36px;
    line-height: 50px;
    color: var(--white-color);
    text-align: center;
}

.signBlock__rightBox {
    height: 100%;

    display: flex;
    justify-content: center;
    align-items: center;
    gap: 32px;
}

.signWrapper {
    width: 540px;
    margin: 80px auto 0;
}

.signForm {
    max-width: 600px;
    margin: 0 auto;
}

.signForm__header {
    margin-bottom: 10px;

    font-size: 48px;
    line-height: 50px;
    color: var(--white-color);
    text-align: center;
}


.signForm__topContent {
    margin-bottom: 10px;

    font-size: 35px;
    line-height: 51px;
    color: var(--white-color);
    text-align: center;
}

.signForm__input {
    width: 100%;
    height: 74px;
}

.signForm .ant-checkbox-inner {
    width: 25px;
    height: 25px;

    border-radius: var(--border-radius);
}

.signForm .ant-checkbox-inner:after {
    width: 12px;
    height: 13px;
}

.signForm__agreementContent {
    margin-bottom: 0;

    font-family: Montserrat, sans-serif;
}

.signForm__btn-wrapper {
    margin-top: 20px;
}
.signForm__submitBtn {
    width: 100%;
    height: 60px;

    background: #ffb257;
    border-style: none;
    border-radius: var(--border-radius);
    font-size: 36px;
    transition: all 0.3s ease-in-out;
}

.signForm__submitBtn:active {
    transform: scale(0.9);
}

.signForm__inputWrapper .ant-input-affix-wrapper {
    border-radius: var(--border-radius);
}

.signForm .ant-input-affix-wrapper > input.ant-input {
    font-size: 36px;
    line-height: 58px;
    color: var(--black-text-color);
}

.signForm__btn-wrapper .ant-form-item {
    margin-top: 30px;

    text-align: center;
}

.signForm__btn-wrapper {
    margin-bottom: 12px;
}

.signForm__agreementContent {
    font-size: 22px;
}

.signForm .ant-form-item-control-input-content {
    display: flex;
    gap: 10px;

    text-align: left;
}

.signForm__inputWrapper .anticon svg {
    width: 40px;
    height: 40px;
}

.signForm__textLink {
    font-size: 24px;
    line-height: 35px;

    text-decoration-line: underline;

    color: var(--white-color);
}

.signForm__inputWrapper {
    text-align: center;
}
