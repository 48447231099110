.statuses__item {
    width: 230px;
    height: 100%;
    padding:10px;
    border-radius: var(--border-radius);
}

.statuses__item-buttons {
    height: 24px;
    width: 100%;
    margin-top: 5px;

    display: grid;
    grid-template-columns: 1fr 24px;
    gap: 10px;
}

.statuses__taskList {
    height: 100%;
    margin-top: 16px;
}

.statuses__taskList-inner {
    height: 76vh;
    position: relative;
    z-index: 2;
}

.statuses__taskList-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.statuses__item-deleteStatusItem {
    padding: 6px;
    transition: all 0.2s ease-in-out;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: var(--main-text-color);
    cursor: pointer;
}

.statuses__item-dropdownMenu {
    display: flex;
    flex-direction: column;
}

.statuses__item-deleteStatusItem:hover {
    background: rgba(178, 175, 175, 0.19);
}

/* .status__item-popover .ant-popover-content {
    transform: translate(23px, -14px);
    padding: 0;
}

.status__item-popover .ant-popover-inner-content {
    padding: 6px 0 6px 6px;
} */

.status__item-containerDropdownMenuItem {
    width: 150px;

    font-size: 18px;
    padding: 6px 28px 6px 6px;
    color: var(--main-text-color) !important;
}

.status__item-editInput {
    width: 170px;
}

.status__item-dropdownMenuItem {
    width: auto;

    font-size: 16px;
    color: var(--main-text-color);
    text-overflow: initial;
    display: block;
}
