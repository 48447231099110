.core-info {
    margin-top: 13px;
    height: fit-content;
    padding: 6px 24px 12px 24px;
    max-width: 1150px;
    border-radius: var(--border-radius);
    background: var(--white-color);
}

.core-info-tab-title {
    transition: color 0.2s ease-in-out;
}

.core-info-tab-title:hover {
    color: var(--light-blue-success);
}

.core-info-scrollbar {
    padding: 0 10px 0 0;
    max-width: 1140px;
    max-height: calc(100vh - 350px);

    background: var(--white-color);
    border-radius: var(--border-radius);
}

.core-info-scrollbar .simplebar-mask {
    width: 100% !important;
}

.core-info-scrollbar .simplebar-track.simplebar-vertical {
    right: -11px;
}


.col-info-photos {
    margin-top: 10px;

    display: grid;
    grid-template-columns:176px 1fr;

    font-size: 16px;
    line-height: 29px;
    color: var(--main-text-color);
}

.col-info-photos-title {
    display: flex;
    align-items: center;

    font-size: 16px;
    color: var(--main-text-color);
}

.col-info-btn-wrap{
    margin-top: 10px;
    text-align: left;
  }

  .col-info-btn-wrap button {
    width: 320px;
  }
  
  .coreInfo__image {
    width: 400px;
    height: 442px;
  }
  
