.wellLogging__tabPanel{
    display: flex;
    gap: 20px;
}

.wellLogging__label{
    font-size: 20px;
    color:var(--main-text-color);
    cursor: pointer;
    transition: all .2s ease-in-out;
}

.wellLogging__active{
    transition: all .3s ease-in-out;
    color: #1890ff;
    position: relative;
}

.wellLogging__active:after{
    content: '';

    background: #1890ff;
    height: 1px;
    width: 100%;
    position: absolute;
    bottom: -2px;
    left: 0;
}

.wellLogging__label:hover{
    color: var(--light-blue-success);
}

.wellLogging__active:after:hover{
    color:var(--light-blue-success);
}


.wellLogging__active:hover{
    color: var(--light-blue-success);
}

.wellLogging__currentBlock{
    margin-top: 20px;
}

.logs__upload .ant-upload-list {
    gap: 10px !important;
}