@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&family=Lobster&display=swap');

.quill{
  border: 1px solid #d9d9d9 !important;
  border-radius: 5px !important; 
}

.ql-toolbar.ql-snow{
  border-style: none !important;
  border-bottom: 1px solid #d9d9d9 !important;
}

.ql-container.ql-snow{
  border-style: none !important;
}

.rdw-option-wrapper{
    border-style: none !important;
    box-shadow: none !important;
    opacity: .5 !important;

    transition:all .2s ease-in;
}

.rdw-option-wrapper:hover{
    opacity: 1 !important; 
}

.rdw-option-active{
    opacity: 1 !important; 
}

.rdw-option-wrapper {
    border: none !important;
    padding: 0px !important;
    min-width: initial !important;
    height: auto !important;
    /* /* border-radius: 2px; */
}

.rw-editor-main{
    font-family: Montserrat;
    font-size: 14px;
}



/* Кастомные стили для шрифтов */
.ql-font-Roboto {
  font-family: 'Roboto', sans-serif;
}

.ql-font-Lobster {
  font-family: 'Lobster', cursive;
}

.app .ql-editor {
    font-family: "Ubuntu";
  }
  
  /* Set dropdown font-families */
  .ql-font span[data-label="Ubuntu"]::before {
    font-family: "Ubuntu";
  }
  .ql-font .ql-font span[data-label="Raleway"]::before {
    font-family: "Raleway";
  }
  .ql-font span[data-label="Roboto"]::before {
    font-family: "Roboto" !important;
  }
  
  /* Set content font-families */
  .ql-font-Raleway {
    font-family: "Raleway";
  }
  .ql-font-Roboto {
    font-family: "Roboto";
  }


  .ql-picker.ql-font {
    .ql-picker-item {
      font-size: 0;
      &:before {
        content: attr(data-value) !important;
        font-size: 14px;
      }
    }
  }
  
  .ql-picker.ql-font{
    .ql-active {
      &:before {
        content: attr(data-value) !important;
        font-size: 14px;
      }
    }
  }
  
  .ql-picker.ql-font .ql-picker-label[data-value="Roboto"]::before, 
  .ql-picker.ql-font .ql-picker-item[data-value="Roboto"]::before
  {
      font-family: "Montserrat";;
      content: "Roboto" !important;
      color: var(--main-text-color) !important;
      font-size: 14px !important;
      font-style: normal !important;
      font-weight: 400 !important;
  }
  
  .ql-picker.ql-font .ql-picker-label[data-value="Raleway"]::before, 
  .ql-picker.ql-font .ql-picker-item[data-value="Raleway"]::before
  {
      font-family: "Montserrat";
      content: "Raleway" !important;
      color: var(--main-text-color) !important;
      font-size: 14px !important;
      font-style: normal !important;
      font-weight: 400 !important;
  }
  
  .ql-picker.ql-font .ql-picker-label[data-value="Montserrat"]::before, 
  .ql-picker.ql-font .ql-picker-item[data-value="Montserrat"]::before
  {
      font-family: "Montserrat";;
      content: "Montserrat" !important;
      color: var(--main-text-color) !important;
      font-size: 14px !important;
      font-style: normal !important;
      font-weight: 400 !important;
  }
  
  .ql-picker.ql-font .ql-picker-label[data-value="Lato"]::before, 
  .ql-picker.ql-font .ql-picker-item[data-value="Lato"]::before
  {
      font-family: "Montserrat";;
      content: "Lato" !important;
      color: var(--main-text-color) !important;
      font-size: 14px !important;
      font-style: normal !important;
      font-weight: 400 !important;
  }
  
  .ql-picker.ql-font .ql-picker-label[data-value="Rubik"]::before, 
  .ql-picker.ql-font .ql-picker-item[data-value="Rubik"]::before
  {
      font-family: "Montserrat";;
      content: "Rubik" !important;
      color: var(--main-text-color) !important;
      font-size: 14px !important;
      font-style: normal !important;
      font-weight: 400 !important;
  }
  

  .ql-formats{
    margin-right: 0 !important;
  }

  .ql-formats button{
    padding: 0;
  }

  .ql-formats button:hover path{
    fill: var(--primary-color);
  }

  .ql-active path{
    fill: var(--primary-color);
  }

  .ql-snow .ql-picker.ql-size .ql-picker-label[data-value=small]::before, .ql-snow .ql-picker.ql-size .ql-picker-item[data-value=small]::before{
    content: '10' !important;
    font-family: "Montserrat";
    color: var(--main-text-color) !important;
  }

  .ql-snow .ql-picker.ql-size .ql-picker-label[data-value=large]::before, .ql-snow .ql-picker.ql-size .ql-picker-item[data-value=large]::before{
    content: '18' !important;
    font-family: "Montserrat";
    color: var(--main-text-color) !important;
  }
  .ql-snow .ql-picker.ql-size .ql-picker-label[data-value=huge]::before, .ql-snow .ql-picker.ql-size .ql-picker-item[data-value=huge]::before{
    content: '32' !important;
    font-family: "Montserrat";
    color: var(--main-text-color) !important;
  }
 