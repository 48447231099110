.participant{
  display: flex;
  align-items: center;
  gap: 5px;
}

.participant-email{
  width: 240px;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow-wrap: break-word;
  overflow: hidden;
}

.participant img{
  width: 20px;
  height: 20px;

  object-fit: contain;
  border-radius: 50%;
}

.participant__user{
  width: 20px;
  height: 20px;

  object-fit: contain;
  border-radius: 50%; 

  font-size: 10px;

  color:var(--white-color);
  background-color: var(--main-text-color);
}