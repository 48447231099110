/* .tagsList .simplebar-track.simplebar-vertical {
    right: 2px;
} */

.tagsList-item,
.tagsList-item-system {
    /*padding: 0 8px;*/
    width: 192px;
    cursor: pointer;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: var(--second-text-color);
}

.tagsList-item-system {
    position: relative;
    transition: all 0.2s ease-in-out;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: var(--second-text-color);
}

.tagsList-item-system button {
    border-style: none;
    background: transparent;

    position: absolute;
    right: 8px;
    top: 50%;
    transform: translateY(-63%);
}

.tagsList-item-systemIcon {
    transition: all 0.2s ease-in-out;
}

.tagsList-item-system:hover .tagsList-item-systemIcon {
    transform: rotate(-90deg);
}

.tagsList-item:hover,
.tagsList-item-system:hover {
    background: rgba(178, 175, 175, 0.19);
    position: relative;
    width: 192px;
}

.tagsList-item-onHoverList {
    width: 70px;
    position: absolute;
    top: 0;
    right: -68px;
    display: none;
    background: #ffffff;
    border: 0.4px solid #b3afaf;
}

.tagsList-item-system:hover .tagsList-item-onHoverList {
    display: block;
}
.tagsList-item-nestedItem {
    width: 70px;
    transition: all 0.2s ease-in-out;
    padding: 4px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: var(--second-text-color);
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}
.tagsList-item-nestedItem:hover {
    background: rgba(178, 175, 175, 0.19);
    cursor: pointer;
}

.tagsList-item-onHoverList .simplebar-track.simplebar-vertical {
    right: 1px;
}

.tagsList__cascader {
    margin-top: 10px;
}

/*new design*/
.recent-tags-list,
.system-tags-list {
    max-width: 100%;

    display: flex;
    flex-direction: column;
    gap: 8px;

    overflow-y: auto;
}

.recent-tags-list > div {
    max-height: 180px;

    display: flex;
    flex-direction: column;
    gap: 8px;
}

.system-tags-services > div {
    max-height: 160px;
}

.recent-tags-list > div > li,
.system-tags-list > div > li {
    max-width: 280px;
}

.tag-element {
    padding: 4px;
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;
    border-radius: var(--border-radius);
}

.tag-element--selected {
    background-color: rgb(222, 229, 251);
}

.system-tags-list {
    margin: 0;
    padding: 0;
}

.system-tags-inner {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.tag-btn--select,
.tag-btn--delete {
    width: 110px;

    font-size: 12px;
    color: var(--main-text-color);
    border-radius: var(--border-radius) !important;
}

.system-tags-breadcrumbs {
    display: flex;
    align-items: center;
    gap: 4px;
}

.system-tags-breadcrumbs > div:first-child {
    height: 24px;
}

.system-tags-breadcrumb {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.system-tags-breadcrumb svg {
    transform: rotate(180deg);
}

.system-tags-list-buttons-list {
    margin-top: 10px;
    display: flex;
    align-items: center;
    gap: 12px;
}

.tags-search {
    max-width: 280px;
}

.tags-skeleton {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.tags-skeleton__item {
    width: 100% !important;
    max-width: 280px !important;
    height: 28px;
}
.tags-skeleton__item span {
    height: 28px !important;
}
