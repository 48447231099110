.activity {
    width: 100%;
    background: #e9eff3;
}

.activity__container {
    display: grid;
    grid-template-columns: 322px 1fr;
}

.activity__entitiesSpace {
    padding: 0 24px 0 0;
    /*background: #e9eff3;*/
    /*border: 2px solid;*/
}
