.scheduler__commentItem {
    margin-bottom: 15px;

    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
    color: var(--second-text-color);
}
.scheduler__commentItem:last-child {
    margin-bottom: 0;
}

.scheduler__commentItem-phone {
    margin-right: 8px;
    width: 22px;
    height: 22px;
    background: #ad4fc5;
    border-radius: 50%;
}
.scheduler__commentItem-name {
    margin-right: 18px;
}
.scheduler__commentItem-bottom {
    margin: 8px 0 0 30px;
}

.commentItem__img {
    width: 22px;
    height: 22px;
    margin-right: 8px;

    object-fit: cover;
    border-radius: 50%;
}
