.select-wrapper{
  width: 100%;
  position: relative;

  overflow: hidden;
}

.ant-select{
  background-color: var(--white-color) !important;
}
 
.ant-select-disabled{
  background: #f5f5f5 !important;
  opacity: .5;
}

.select-wrapper-moved-icon {
  position: absolute;
  right: 8px;
  top: 37%;
  z-index: 2;
  transition: all 0.3s ease-in-out;
}

.select-wrapper-moved-icon-opened{
  transform: rotate(180deg);
}

.select-wrapper-moved-icon-closed{
  transform: rotate(0deg);
}



.select-wrapper .ant-select{
  width: 100%;
  border-radius: var(--border-radius) !important;
}

.select{
  width: 100%;
}

.ant-select-dropdown {
  border-radius: var(--border-radius) !important;
}