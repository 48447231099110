.core-boxes-photos-list{
    margin-top: 10px;
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
}

.core-boxes-photos-list{
    display: flex;
    align-items: center;
    gap: 12px;
}

.core-boxes-skeleton-item{
    width: 50px !important;
    height: 50px !important;
    border-radius: var(--border-radius);
    overflow: hidden;
}

.core-boxes-photos-list li{
    width: 50px;
    height: 50px;
    flex-shrink: 0;
    cursor: pointer;
    border:1px solid transparent;
    position: relative;
}

.core-boxes-photos-list svg{
    position: absolute;
    top: -10px;
    right: -8px;
    opacity: 0;
    transition: all .2s ease;
}

.core-boxes-photos-list li:hover svg{
    opacity: 1 !important;
}

.core-boxes-photos-active-item{
    border:1px solid var(--primary-color) !important;
    border-radius: var(--border-radius);
}

.core-boxes-photos-list li img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: var(--border-radius);
}

.core-boxes-photos-abcent-data{
    font-size: 14px;
    color:var(--main-text-color);
}