.profile {
    display: grid;
    display: grid;
    grid-template-columns: 80px 1fr;
    grid-template-rows: repeat(3, auto);
    grid-template-areas:
        'a b'
        'c k'
        'd d';

    column-gap: 30px;
}

.profile__avatar {
    grid-area: a;

    width: 80px;
    height: 80px;
    

}

.profile__avatar img{
    width: 100%;
    height: 100%;

    border-radius: 50%;
}

.profile__form {
    grid-area: b;
}

.profile__form-fields {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-auto-flow: row;
    gap: 12px;
}

.profile__form-absent{
    grid-area: c;
}

.profile__form-files {
    grid-area: k;
}

.profile__btn {
    margin-left: auto;

    grid-area: d;
}

.profile__form-skeleton{
    height: 50px !important;
}

.profile__avatar-skeleton{
    width: 80px !important;
    height: 80px !important;

    border-radius: 50% !important;
}
