.new-project {
    width: 284px;
    height: 470px;
    margin: 10px 0 0 20px;
    border-radius: var(--border-radius);
}

.new-project-container {
    padding: 10px 10px 10px 30px;
    background-color: var(--white-color);
    position: relative;
}

.new-project__cancelBtn {
    position: absolute;
    top: 10px;
    right: 12px;

    background: transparent;
    border: none;
    cursor: pointer;
}

/*Form*/

.new-project__form {
    max-width: 218px;
}

.new-project__formItem {
    color: var(--second-text-color);
    max-width: 230px;
}


.new-project__formBtn {
    width: 100%;
    height: 35px;
    margin-top: 10px;

    text-align: center;
    font-family: Montserrat, sans-serif;
    font-size: 15px;
    line-height: 22px;

    background: rgba(141, 199, 218, 0.7);
    border: 0.4px solid #8dc7da;
    border-radius: var(--border-radius) !important;
}

.new-project__formItem .ReactFlagsSelect-module_filterBox__3m8EU input {
    height: 40px;
    border-radius: var(--border-radius);
}

.new-project__formItem .ReactFlagsSelect-module_flagsSelect__2pfa2 {
    width: 100%;
    height: 28px;
    padding: 0;
    background: #e9eff3;
    border: 0.4px solid #e9eff3;
    border-radius: var(--border-radius);
}

.new-project__formItem .ReactFlagsSelect-module_selectBtn__19wW7 {
    border-style: none;
    border-radius: var(--border-radius);
    font-size: 20px;
    height: 25px;
    padding: 0 6px 0 9px;
    width: 220px;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow-wrap: break-word;
    overflow: hidden;
    line-height: 13px;
}

.flagsSelect {
    height: 300px;
}

.new-project__form-item button{
    width:100%;
}
