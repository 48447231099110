.task-details {
    display: grid;
    grid-template-columns: 49% 49%;
    grid-template-rows: repeat(3, auto);
    gap: 10px;
}

.task-details-skeleon__wrap{
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.task-details-skeleon{
    width: 100% !important;
}

.task-details-skeleon__label{
    height: 24px !important;
}
.task-details-skeleon__field{
    height: 30px !important;
}

@media (max-width:1180px) {
    .task-details{
        grid-template-columns: 1fr;
    }
}