.select-wrapper{
    width: 100%;
    position: relative;
  
    overflow: hidden;
  }
  
  .ant-select{
    background-color: transparent !important;
  }

 .ant-select-selector {
    border-radius: var(--border-radius) !important;  /* Применяем кастомный border-radius */
    height: 30px !important;
  }

  .ant-select-multiple .ant-select-selector{
    height: fit-content !important;
  }

  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input{
    height: fit-content !important;
  }
   
  .ant-select-disabled{
    background: #f5f5f5 !important;
    opacity: .5;
  }
  
  .select-wrapper-moved-icon {
    position: absolute;
    right: 8px;
    top: 37%;
    z-index: 1;
    transition: all 0.3s ease-in-out;
  }
  
  .select-wrapper-moved-icon-opened{
    transform: rotate(180deg);
  }
  
  .select-wrapper-moved-icon-closed{
    transform: rotate(0deg);
  }
  
  .select-wrapper .ant-select{
    width: 100%;
    border-radius: var(--border-radius) !important;
  }
  
  .select{
    width: 100%;
  }
  
  .ant-select-dropdown {
    border-radius: var(--border-radius) !important;
  }

  .ant-select-selection {
    color: var(--main-text-color);
    font-size: 16px;
}

/*цвет границы */
.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
  border-color: #d9d9d9;
}

.ant-select:not(.ant-select-disabled):focus .ant-select-selector {
  border-color: #d9d9d9;
}

/**все стили для сселектов/
/*стили для селекта при фокусе*/
.ant-select-focused .ant-select-selector,
.ant-select-selector:focus,
.ant-select-selector:active,
.ant-select-open .ant-select-selector {
    border-color: #d9d9d9 !important;
    box-shadow: none !important;
    border-radius: var(--border-radius)!important;
}

.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
    border-radius: var(--border-radius) !important;
}

.coreStudyInput
    .ant-select:not(.ant-select-customize-input)
    .ant-select-selector {
    border-radius: var(--border-radius) !important;
}

.ant-select-focused {
  border-color: #d9d9d9;
}

.ant-select-selection-overflow-item-suffix{
  width:  auto!important;
}

.ant-select-selection-overflow-item{
  max-width: 180px !important;
}


