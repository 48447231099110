.coreStudySetting__top {
    max-width: 300px;

    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 10px;
    align-items: center;
}

.coreStudySetting__measurementUnits {
    margin-top: 5px;
    max-width: 302px;

    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;

    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: var(--main-text-color);
}

.coreStudySetting__measurementUnits > div {
    text-align: center;
}

.capillaryCurve__item {
    grid-template-columns: 146px 146px 1fr;
}
