.FirstProject {
    width: 285px;
    height: 450px;
    border-radius: var(--border-radius);
    background: #ffffff;
    margin-left: 15%;
    margin-top: 20px;
    display: flex;
}
.firstproject_form {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}
.firstproject_datainput {
    width: 75%;
    height: 16%;
    display: flex;
}
.firstproject_continue_box {
    width: 75%;
    height: 20%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    position: relative;
}
.firstproject_label {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    font-size: 20px;
    line-height: 30px;
    color: var(--second-text-color);
}

.firstproject_input {
    width: 100%;
    height: 30px;
    background: #e9eff3;
    border-radius: var(--border-radius);
    border: none;
    text-indent: 20px;
    font-size: 20px;

    color: var(--second-text-color);
}

.firstproject_submit {
    width: 100%;
    height: 30px;
    background: rgba(141, 199, 218, 0.7);
    border: 0.4px solid #8dc7da;
    border-radius: var(--border-radius);
    font-size: 15px;
    line-height: 22px;
    text-align: center;
    cursor: pointer;
    color: var(--second-text-color);
}
.error_required_text {
    font-size: 15px;
    line-height: 22px;
    color: var(--error-text-color);
    position: absolute;
    top: 3px;
    left: 10%;
}
@media screen and (max-width: 576px) {
    .FirstProject {
        width: 90%;
        margin: 0 auto;
        margin-top: 5%;
    }
}
