.change-photo-menu-content {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.change-photo-menu-content-item {
  display: flex;
  align-items: center;
  gap: 12px;
  font-size: 16px;
  cursor: pointer;
}

.change-photo-menu-file-modal-inner > div {
  width: 100%;
  margin: 0 auto;
  height: 100%;
}

.change-photo-menu-file-modal-inner img {
  width: 100%;
  object-fit: contain;
  height: 100%;
}

.change-photo-menu-content {
  font-size: 16px;
}

.photo-upload-input-file {
  opacity: 0;
  height: 0;
  width: 0;
  position: absolute;
}