.core-box-edit-space {
    margin-top: 15px;
}

.core-box-edit-space-top {
    display: flex;
    gap: 10px;
}

.core-box-edit-space-top li {
    padding: 3px 15px;
    font-size: 14px;
    font-weight: 400;
    color: var(--main-text-color);
    cursor: pointer;
}

.core-box-edit-space-top .activeTab {
  background-color: var(--light-blue-success);
  border-radius: var(--border-radius);
}

.core-box-edit-space-content{
    margin-top: 24px;
}
