.modal-top-cancel-icon {
    position: absolute;
    right: 16px;
    top: 17px;
    cursor: pointer;
    z-index: 10;
}

.ant-modal-header {
    border-bottom: 0 !important;
    border-radius: var(--border-radius) var(--border-radius) 0 0 !important;
}
.ant-modal-title {
    font-size: 18px !important;
    color:var(--main-text-color) !important;
    text-align: center;
}

/* .ant-modal-body {
    padding-top: 0 !important;
} */
.ant-modal-footer {
    display: flex;
    gap: 8px;
    justify-content: flex-end;
}

.ant-modal-footer .ant-btn {
    min-width: 120px;
}

.ant-modal-content{
    border-radius: var(--border-radius) !important;
}

.modal-title{
    display: flex;
    align-items: center;
    gap: 8px;
}

.modal-button{
    font-size: 14px !important;
    color:var(--main-text-color) !important;
    line-height: 20px !important;
}

.modal-button-primary{
    color:var(--white-color);
}
