.team {
    background: #e9eff3;
    width: 100%;
}

.team-top{
    display: flex;
    align-items: center;
    gap: 20px;
}
.team__container {
    padding: 20px 15px 10px 30px;
}

.team__table {
    margin-top: 24px;
    height: 70vh;
}
.team__table-list {
    margin-top: 16px;
    max-width: 1120px;
    border-radius: var(--border-radius);
}


@media (max-width:1480px){
    .team__container{
        height: 100%;
        max-width: calc(100vw - 300px);
        overflow-x: auto;
    }
    .team__table-list{
        width: 1120px;
    }
}


@media screen and (max-width: 991px) {
    .team__container {
        padding-left: 10px;
        padding-right: 10px;
        max-width: calc(100vw - 200px);
    }

}