.projects__elements-wells {
    margin-top: 14px;

    display: flex;
    flex-direction: column;

    border-radius: var(--border-radius);
}

.projects__elements-wellList {
    border-radius: var(--border-radius);
    background-color: var(--white-color);
}

.projects__elements-wells-sb {
    max-height: calc(100vh - 350px);
}

.projects__elements-wells-listInner {
    max-height: calc(100vh - 350px);
}

.projects__elements-wellContainer {
    /*padding: 8px 25px 8px 32px;*/
    padding: 5px 0 5px 8px;
    width: 100%;

    /*margin-bottom: 5px;*/

    display: flex;
    align-items: center;
    gap: 7px;

    position: relative;

    font-size: 18px;
    color: var(--main-text-color);
    border-radius: var(--border-radius);
    overflow: hidden;
}

.projects__elements-wells-plusIcon {
    /*position: absolute;*/
    top: 50%;
    left: 12px;
    /*transform: translateY(-50%);*/
}

.projects__elements-wells-editableSpan {
    max-width: 105px;
}

.projects__elements-wells-editableInput {
    max-width: 124px;
}

.projects__elements-wellBoreItem {
    padding: 5px 8px 5px 26px;
    border-radius: var(--border-radius);

    position: relative;
    font-size: 18px;
    color: var(--main-text-color);
}

.wellBoreInput {
    width: 100%;
    /*height: 25px;*/
}

.projects__elements-wellBore-editSpan {
    max-width: 100px;

    display: inline-flex;
}

.projects__elements-wellBore-editSpan svg {
    right: -24px;
}

.projects__elements-wellBore-editSpan:hover svg {
    visibility: visible;
}

.well-item--skeleton:nth-child(1) {
    margin-top: 0;
}

.well-item--skeleton {
    margin-top: 5px;
    border-radius: var(--border-radius);
}

.well-item--skeleton .ant-skeleton-input {
    height: 34px !important;
}

.projects__elements-wellList-loading{
    background-color: transparent !important;
}
