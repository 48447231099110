.value-range-block{
  margin-top: -5px;
  display: flex;
  flex-direction: column;
}

.value-range-block .ant-radio-wrapper{
  height: 23px !important;
  align-items: center;
}

.value-range-block .fields-interval{
  max-width: 110px;
}

.value-range-block--text{
  transform: translateY(4px);
}