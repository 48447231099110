.select-interval{
    display: flex;
    align-items: center;
    gap: 8px;
}

.selection-intervals-skeleton-item{
    width: 346px !important;
    border-radius: var(--border-radius) !important;
}

.select-interval-checkbox{
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border:1px solid var(--main-text-color);
    cursor: pointer;
}

.select-interval-checkbox--selected{
    background-color: var(--primary-color);
}

.select-interval-checkbox--disabled{
    background-color: var(--light-gray);
}

.selection-intervals-list{
    margin: 15px 0 0 2px;
    display: grid;
    grid-template-columns: 300px 300px;
    grid-auto-flow: row;
    gap: 8px;
}

.selection-interval-block{
    width: 100%;
    padding: 0 5px;
    display: inline-flex;
    align-items: center;
    gap: 5px;
    border-radius: var(--border-radius);
}

.selection-interval-block--success{
    background-color: var(--success-field-bg);
    border:1px solid var(--green-success);
}

.selection-interval-block--error{
    background-color: var(--bg-error);
    border:1px solid var(--border-error);
}

.selection-intervals-select {
    width: 135px;
}

.selection-intervals-select .ant-select-selector{
    padding: 0 5px 0 0 !important;
}

.selection-intervals-select .ant-select-selection-item{
    padding: 0 !important;
}

.selection-intervals-textFields{
    display: flex;
    align-items: center;
    gap: 3px;
}

.selection-intervals-textFields .input-wrapper{
    width: 70px;
}

.selection-intervals-textFields .input-wrapper input{
    border-style: none;
    background-color: var(--light-gray) !important;
    border-radius: var(--border-radius);
    height: 25px;
}

.selection-interval-cancel-icon{
    margin-left: 4px;
    transform: translateX(-3px);
    flex: 1 0;
}