.gis-table{

}

.gis-table-block-gis-table{
  border: 1px solid var(--gray-color);
}

.gis-table-block-gis-table .table-top{
  display: grid;
  grid-template-columns:auto 35% 30% 35%;
  padding: 0;
}

.gis-table-block-gis-table .table-top span:nth-child(3){
  border-left: 1px solid var(--gray-color);
  border-right: 1px solid var(--gray-color);
}

.gis-table-block-gis-table .table-top span{
  padding: 5px 10px 0;
  border-bottom: 1px solid var(--gray-color);
}

.gis-table-middle, .gis-table-end{
  display: grid;
  column-gap: 10px;
  grid-template-columns: 1fr 1fr;
  border-left: 1px solid var(--gray-color);
  border-right: 1px solid var(--gray-color);
  align-items: center;
}

.gis-table-end{
  grid-template-columns: 1fr 1fr 1fr 1fr;
  border-left: none;
  border-right: none;
}

.gis-table-item {
  display: grid;
  grid-template-columns: 35% 30% 35%;
  position: relative;
  color: var(--main-text-color);
}

.gis-table-radio-btn{
  position: absolute;
  right: -25px;
  top: 50%;
  transform: translateY(-50%);
}

.gis-table-top-row{
  grid-template-columns: 0.2fr 1.2fr 1.2fr .8fr .8fr .2fr !important;
  gap: 10px;
}

.gis-table-btn{
  padding: 3px 7px;
  color: var(--white-color);
  background-color: #2BAAEF;
}

.gis-table-mnemonic-group .table-top{
  margin-bottom: 5px;
  display: grid;
  grid-template-columns: 0.2fr 1.2fr 1.2fr .8fr .8fr .2fr !important;
  gap: 10px;
  align-items:center;
  background: rgba(217, 217, 217, 0.2);
}

.gis-table-mnemonic-group .table-top > div{
  margin: 0 auto;
}

.gis-table-mnemonic-group--row-list{
  display: grid;
  grid-template-columns: 0.2fr 1.2fr 1.2fr .8fr .8fr .2fr !important;
  gap: 10px;
  align-items:center;
}

.gis-table-mnemonic-group-priority-btn-wrapper{
  justify-self: center;
  height: 16px;
}

.gis-table-mnemonic-group-priority-btn{
  width: 16px;
  height: 16px;
}

