.input-wrapper{
  height: 100%;
  position: relative;
  .input{
    //background: var(--gray-bg-color);
    color:var(--main-text-color);
    font-size: 14px !important;
    border-radius: var(--border-radius) !important;
  }

  .input-error-text{
    font-size: 12px;
    color:var(--error-text-color);

    position: absolute;
    left: 0;
    bottom: -20px;
  }
}

.ant-input:focus{
  box-shadow: none !important;
}

.input-wrapper-error{
  .input{
    //background: var(--gray-bg-color);
    border-color:var(--error-text-color) !important;
    background-color: var(--bg-error) !important;
  }
}

.ant-input-affix-wrapper > input.ant-input {
  font-size: 14px;
  color: var(--main-text-color);
}

