.scheduler__menu-userLogo {
    /*padding-top: 1px;*/
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    border-radius: 50%;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: var(--white-color);
    transition: all 0.2s ease-in-out;
    cursor: pointer;
}
.scheduler__menu-userLogo:hover {
    border: 1px solid #0092c7;
}

.scheduler__menu-userImg {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    object-fit: cover;
}
