.addonsInput {
  display: grid;
  grid-template-columns: 50% 50%;


  .customSelect {

    & .ant-input-select{
      height: 30px;
    }
    height: 30px;

    border: 1px solid #d9d9d9;
    border-radius: var(--border-radius) 0 0 var(--border-radius);
  }

}

.inputText {
  border: 1px solid #d9d9d9;
  border-radius: var(--border-radius) 0 0 var(--border-radius);
  padding: 0 4px 0 6px;
}

.input {
  height: 30px;
  border-radius: 0 var(--border-radius) var(--border-radius) 0 !important;
  font-size: 16px !important;
  color: var(--main-text-color) !important;
}








