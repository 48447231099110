.editableSpan__container{
    padding: 0 40px 0 12px;
    position: relative;
}

.editableSpan__container > span{
    word-wrap: normal;
    word-break: break-all;
    overflow-wrap: break-word;
}

.editableSpan__container:hover{
    background-color: var(--grey-phone-color);

}

.editableSpan__container:hover .editableSpan__icon{
    visibility: visible;
}

.editableSpan__error {
    margin: 0;
    padding: 0;
    position: absolute;
}


.editableSpan__icon{
    position: absolute;
    right: 0;
    top: 0;
    transform: translate(-50%);
    visibility: hidden;
}

