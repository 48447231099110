.ant-input-affix-wrapper {
    border-radius: var(--border-radius) !important;
    
}

input:disabled {
    cursor: not-allowed;
    opacity: .5;
}

.ui-input{
    color:var(--main-text-color) !important;
    height: 30px !important;
}

input[type='password'] {
    letter-spacing: 10px;
}

input::placeholder {
    letter-spacing: normal;
}

label {
    font-size: 16px !important;
    color: var(--main-text-color);
}

.input-mask {
    width: 100%;
    height: 30px;
    padding: 4px 11px;
  
    font-size: 14px;
    line-height: 30px;
    color: var(--main-text-color);
    border: 1px solid var(--field-border);
    border-radius: var(--border-radius) !important;
    transition: all 0.2s ease-in-out;
  }
  
  :root[data-theme='light'] .card-number-field-wrap .input-mask {
    background-color: var(--blue-secondary-light) !important;
  }
  :root[data-theme='dark'] .card-number-field-wrap .input-mask {
    background-color: var(--blue-secondary-dark) !important ;
  }
  
  :root[data-theme='light'] .input-mask {
    border-color: var(--gray-stroke-light);
  }
  :root[data-theme='dark'] .input-mask {
    border-color: var(--gray-stroke-dark);
  }
  
  .input-mask:focus-visible {
    box-shadow: none;
    outline: none;
  }
  
  :root[data-theme='light'] .input-mask:focus-visible {
    border-color: var(--blue-primary-light);
  }
  :root[data-theme='dark'] .input-mask:focus-visible {
    border-color: var(--blue-primary-dark);
  }
  
  @media (min-width: 481px) {
    :root[data-theme='light'] .input-mask:hover {
      border-color: var(--blue-primary-light) !important;
    }
    :root[data-theme='dark'] .input-mask:hover {
      border-color: var(--blue-primary-dark) !important;
    }
  }
  
  .card-number-field-error {
    border-color: var(--red-color) !important;
  }
  
  :root[data-theme='light'] .card-number-field-error:hover,
  :root[data-theme='dark'] .card-number-field-error:hover {
    border-color: var(--red-color) !important;
  }
  :root[data-theme='light'] .input-mask:disabled {
    border-color: var(--gray-stroke-light) !important;
  }
  
  :root[data-theme='dark'] .input-mask:disabled {
    border-color: var(--gray-stroke-dark) !important;
  }
  
  .input-mask:disabled {
    cursor: not-allowed;
    color: var(--gray-silver) !important;
  }
  
  :root[data-theme='light'] .input-mask:disabled {
    background-color: var(--background-light) !important;
  }
  
  :root[data-theme='dark'] .input-mask:disabled {
    background-color: var(--background-dark) !important;
  }
  
  .card-number-field-wrap {
    padding-top: 4px;
  }
  
  @media screen and (max-width: 480px), (max-width: 1000px) and (orientation: landscape) {
    .card-number-field-wrap {
      position: relative;
    }
  
    .card-number-field-wrap svg {
      position: absolute;
      right: 12px;
      top: 50%;
      transform: translateY(-50%);
    }
  }
  