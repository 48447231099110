.ant-popover-inner-content {
    padding: 10px 0 !important;
}

.ant-popover-inner {
    border-radius: var(--border-radius) !important;
}

.ant-popover-placement-top,
.ant-popover-placement-topLeft,
.ant-popover-placement-topRight {
    padding-bottom: 0 !important;
}

.ant-popover-content {
    transform: translate(-4px, -14px);
}

.ant-popover-inner{
    height: 100%;
}

.popover-tooltip{
    max-width: 400px;
    word-break: break-all;
}
