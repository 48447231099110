.scheduler-task {
    padding: 5px 10px;

    display: grid;
    grid-template-columns: 20px 1fr;
    column-gap: 10px;

    overflow: hidden;
}

.scheduler-template {
    padding: 5px 10px;

    display: grid;
    grid-template-columns: 1fr;
    column-gap: 10px;

    overflow: hidden;
}

.scheduler-task__priority {
    width: 20px;
    height: 20px;
    border-radius: var(--border-radius);
}

.scheduler-task-menu {
    position: absolute;
    right: 8px;
    bottom: 5px;
}

.scheduler-task-menu .popover-menu-icon-wrapper > svg {
    transform: rotate(90deg);
}
