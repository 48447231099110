.scheduler-accordion__arrow {
    width: 20px;
    height: 20px;

    display: flex;
    justify-content: center;
    align-items: center;

    background: var(--white-color);
    border:1px solid var(--main-text-color);
    border-radius: var(--border-radius);
    cursor: pointer;
}
.scheduler-accordion__arrowIcon {
    transition: all 0.3s ease-in-out;
}
.scheduler-accordion__bottom {
    margin: 10px 0 0 30px;
    padding-bottom: 10px;
    transition: all 0.4s ease-in-out;
    overflow: hidden;
}
.scheduler-accordion__bottom-inner {
    transition: all 0.4s ease-in-out;
}
.scheduler__tasksSettings-details {
    margin-top: 10px;
    max-width: 810px;
}
