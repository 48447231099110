.porositySettings__container {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.porositySettings__item {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 0.7fr;
    gap: 10px;
}

.porositySettings__plusBtn {
    cursor: pointer;
    justify-self: right;
}
