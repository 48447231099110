.projects__elements-topItem {
    padding: 0 0 0 7px;
    display: flex;
    align-items: center;
    gap: 8px;
}

.activeTabNav {
    text-decoration: underline;
}

.projects__elements-topItem:nth-child(3) {
    margin-left: 7px;
}

.projects__elements-topItem h4 {
    margin: 0;
    font-size: 18px;
    color: var(--main-text-color);
}

.projects__elements-topItem--upload-btn{
    display: flex;align-items: center;
}
