.tasks-files{
    /* max-width: 920px; */
}

.tasks-files-list{
    margin-top: 12px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 8px;
}

.tasks-files-list li{
    width: 70px;
    height: 70px;

    display: flex;
    align-items: center;
    justify-content: center;

    position: relative;

    cursor: pointer;
    transition: all .2s ease-in-out;
}

.tasks-files-list li img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    flex: 1 0;
    border-radius: var(--border-radius);
}

.tasks-files-item__icon-wrapper{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);

    opacity: 0;
    transition: all .2s ease-in-out;
}


.tasks-files-list li{
    background-color: var(--gray-bg-color);
}

.tasks-files-list li:hover .tasks-files-item__icon-wrapper{
    opacity: 1;
}

.tasks-files-item-bg{
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0;
    background-color: rgba(0, 0, 0, 0.3) !important;
    border-radius: var(--border-radius);
    transition: all .2 ease-in-out;
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
    transition: all .2s ease-in-out;
}

.tasks-files-list li:hover .tasks-files-item-bg{
    opacity: 1;
}


.tasks-files-item__icon--download rect{
    fill: transparent !important;
}

.tasks-files-item__icon--download path{
    fill: transparent !important;
    stroke: var(--white-color);
} 


.tasks-files-preview-modal-title{
    display: flex;
    align-items: center;
    gap: 8px;
}

.tasks-files-preview-modal-title svg{
    cursor: pointer;
}