.core-box-preparing {
    display: grid;
    grid-template-columns: 1fr 250px;
    column-gap: 16px;
}


.core-box-prepaparing-photo-space-content {
    min-height: 370px;
    margin-bottom: 8px;
    background-color: rgba(126, 160, 175, 0.09);
    border-radius: var(--border-radius);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    overflow: hidden;
}

.core-box-prepaparing-photo-space-content img {
    width: 100%;
    object-fit: contain;
    height: 100%;
}




.core-box-prepaparing-photo-space-btn-wrapper {
    padding: 10px;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    gap: 10px;
}

.core-box-prepaparing-content-space .add-core-box-photo-item {
    grid-template-columns: 1fr;
    grid-auto-flow: row;
    gap: 5px;
}

.core-box-prepaparing-photo-color-field {
    width: 30px;
    border-radius: var(--border-radius);
    border: 1px solid var(--gray-color);
    cursor: pointer;
    opacity: .4;
}

.core-box-prepaparing-photo-edit-box {
    display: flex;
    align-items: center;
    gap: 5px;
}

.core-box-prepaparing-photo-icon-item {
    padding: 2px;
    border-radius: var(--border-radius);
    display: flex;
    align-items: center;
    justify-content: center;
}

.core-box-prepaparing-photo-icon-item--active {
    background-color: var(--gray-color);
}

