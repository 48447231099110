.addTextButton__btn {
    padding-left: 5px;
    width: 140px;
    height: 34px;

    font-weight: 400;
    font-size: 14px;
    line-height: 26px;

    display: flex;
    align-items: center;
    justify-content: center;

    background: transparent;
    color: var(--main-text-color);

    border-radius: var(--border-radius);
    border: 2px solid rgba(217, 217, 217, 0.2);
    cursor: pointer;

    transition: all 0.3s ease-in-out;
}

.addTextButton__btn:hover {
    background: rgba(217, 217, 217, 0.2);
}

.addTextButton__inputForm {
    /*margin-top: 12px;*/
    margin-bottom: 10px;
    transition: all 0.3s ease-in-out;
    overflow: hidden;
}

.addTextButton__inputForm input {
    padding: 0 7px 0 14px;
    width: 100%;
    height: 30px;
    background: #f7f7f7;
    border-radius: var(--border-radius);
    border-style: none;
    outline: none;
    font-weight: 400;
    font-size: 14px;
    line-height: 29px;
    color: var(--main-text-color);
}
