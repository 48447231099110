.scheduler__tasks-container {
    width: 100%;
}

.scheduler__tasks {
    width: 100%;
    padding: 0 0 10px 10px;

    display: grid;
    grid-template-columns: 298px 1fr;
    column-gap: 10px;
}

.scheduler__tasksSettings{
    width: 100%;
    height: calc(100vh - 45px);
    overflow-x: auto;

    background-color: var(--white-color);
}

.scheduler__absent-tasks-message{
    padding: 12px 12px 12px 19px;
    line-height: 18px;
}

.scheduler__tasks-itemsList {
    height: 70vh;
}


.scheduler__addTaskPanel {
    padding: 5px 9px 5px 19px;
    background: #e9eff3;
}

.scheduler__addTaskPanel-top {
    display: flex;
    gap: 29px;
}

.scheduler__tasks-wrapper {
    background: #fff;
}

.scheduler__tasks-container{
    max-height: calc(100vh - 240px);

    overflow: hidden;
    overflow-y: auto;
}

.scheduler__tasks-sort-age {
    display: flex;
    align-items: center;
    gap: 5px;
}

.scheduler__tasks-sort-age--down {
    transform: rotate(180deg);
}

.scheduler__tasks-pagination {
    margin-top: 10px;
}

.tasks-spinContainer {
    height: 80vh;
    width: 65vw;
    display: flex;
    justify-content: center;
    align-items: center;
}

.scheduler__tasks-itemsList .simplebar-mask {
    width: 100% !important;
}

.task-space-loading {
    width: 100%;
    height: calc(100vh - 45px);
    display: flex;
    justify-content: center;
    align-items: center;
    background: #e9eff3;
}

.scheduler__tasks-skeleton-wrapper {
    padding: 12px 10px;
    display: flex;
    flex-direction: column;
    gap: 6px;
}

.scheduler__tasks-skeleton-item,
.scheduler__tasks-skeleton-item .ant-skeleton-element .ant-skeleton-input {
    width: 100% !important;
    height: 60.08px !important;
    border-radius: var(--border-radius);
}
.scheduler__tasks-skeleton-item {
    height: 60.08px;
}

.scheduler__create-panel{
    position: absolute;
    right: 0;
    top: 5px;

    z-index: 2;
}
