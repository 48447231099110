.projectsInfoItem {
    max-width: 214px;

    display: flex;
    flex-direction: column;
}

.projectsInfoItem-name {
    color: var(--main-text-color);
    font-size: 20px;
}

/*Editable span*/
.projectsInfoItem-value {
    padding: 0px 20px 0px 9px;

    font-size: 22px;
    line-height: 29px;
    cursor: pointer;
    color: var(--main-text-color);
    word-break: break-word;
}

.projectsInfoItem-value:hover {
    background: rgba(217, 217, 217, 0.2);
    border-radius: var(--border-radius);
}

.projectsInfoItem-value:hover .editSpanIcon {
    visibility: visible;
}

.projectsInfoItem-input {
    width: 100%;
    height: 30px;
    padding: 0 6px 0 14px;

    background: #ffffff;
    border: none;
    border-radius: var(--border-radius);
    font-size: 20px;
    color: var(--main-text-color);
    outline: none;
}

/*Media-query*/
@media screen and (max-width: 650px) {
    .projectsInfoItem {
        grid-template-columns: 2fr 1fr;
    }
}

@media screen and (max-width: 600px) {
    .projectsInfoItem {
        display: block;
    }
}

.projectsInfoItem .ReactFlagsSelect-module_filterBox__3m8EU input {
    height: 27px;
    border-radius: var(--border-radius);
    padding-left: 14px;
}

.projectsInfoItem .ReactFlagsSelect-module_flagsSelect__2pfa2 {
    width: 100%;
    height: 30px;
    padding: 0;
    background: #ffffff;
    border: none;
    border-radius: var(--border-radius);
}

.projectsInfoItem .ReactFlagsSelect-module_selectBtn__19wW7 {
    height: 25px;
    padding: 0px 6px 0px 9px;
    border-style: none;
    border-radius: var(--border-radius);
    font-size: 20px;
    width: 210px;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow-wrap: break-word;
    overflow: hidden;
    line-height: 13px;
}

.ReactFlagsSelect-module_filterBox__3m8EU input {
    border: 1px solid;
}
