.coreStudiesIconWrap {
    border: 1px solid transparent;
    height: 52px;
    width: 52px;
    border-radius: var(--border-radius);
    display: flex;
    /*align-items: center;*/
    justify-content: center;
}

.coreStudiesIconWrap__active {
    border-color: #8695a5;
}
