.roadmap-item {
    width: 100%;
    height: 27px;
    padding-right: 0;

    font-size: 10px;
    line-height: 20px;
    color: var(--main-text-color);
    border-radius: var(--border-radius) 0 0 var(--border-radius);
    background-color: var(--white-color);
}

.roadmap-item-task-data {
    width: 100%;
    height: 100%;

    display: grid;
    align-items: center;
    grid-template-columns: 12px 76px 70px 229px 115px 115px 120px;

    position: relative;
}

.roadmap-item-task-data::after {
    content: '';

    width: 1.4px;
    height: 100%;

    position: absolute;
    right: 0;
    top: 0;

    background-color: var(--field-border);
    transform: translateX(1.7px);
}

.roadmap-item-task-data > div {
    padding-left: 5px;
    padding-right: 5px;
    text-align: center;
}

.roadmap-item-task-data .custom-popover{
    display: flex;
    align-items: center;
    justify-content: center;
}

.roadmap-item-data {
    display: flex;
    align-items: center;
    justify-content: center;

    text-align: center;
}

.creator-with-assignees-wrapper {
    width: 100%;
    max-width: 100%;
    justify-content: center;
}

.roadmap-item-deadline svg {
    flex-shrink: 0;
}

.roadmap-item-data-task {
    height: 20px;
    width: 100%;

    display: flex;
    align-items: center;
    gap: 9px;
}

.roadmap-item-slug {
    height: 20px;

    display: flex;
    align-items: center;
    justify-content: center;

}

.roadmap-item-slug div {
    padding: 0 5px;
    font-size: 12px;
    text-align: center;
}

.roadmap-item-slug span {
    width: 50px;

    display: -webkit-box;
    text-overflow: ellipsis;
    overflow-wrap: break-word;

    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}

.roadmap-item-data-task-name {
    font-size: 10px;
    line-height: 13px;
    word-break: break-word;

    max-width: 230px;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow-wrap: break-word;
    overflow: hidden;
}

.roadmap-item-data-task-name span{
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.roadmap-item-members {
    height: 19px;
    width: 100%;

    gap: 7px;
    display: flex;
    align-items: center;
}

.roadmap-item-members .membersSelect__bottom-item {
    padding: 0;
    width: 22px;

    left: 0;
}

.roadmap-item-superior {
    width: 19px;
    height: 19px;

    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;

    border-radius: 50%;
    color: var(--white-color);
    background-color: #80c7b2;
}

.roadmap-item-deadline {
    width: 100%;
    padding: 0 5px;

    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;

    font-size: 12px;
    color: var(--main-text-color);
    line-height: 15px;
}

.roadmap-item-deadline-error {
    color: var(--deadline-error);
}


.roadmap-item-superior {
    background-color: #6fa0e9;
}

.roadmap-teim-members {
    display: flex;
    align-items: center;
}

.roadmap-item-status {
    width: 100%;
    display: flex;
    align-items: center;
    text-align: center;
    
    font-size: 12px;
}

.roadmap-item-status > span {
    width: 100%;
}

.roadmap-item-tools {
    display: flex;
    align-items: center;
    gap: 8px;
}

.roadmap-item-tools svg {
    flex: 1 0;
}

