.signBtn {
    padding: 0 12px;
    height: 55px;

    display: flex;
    align-items: center;
    justify-content: center;

    background: #ffffff;
    border: 1px solid var(--second-text-color);
    border-radius: var(--border-radius);
    position: relative;
    z-index: 1;

    font-family: inherit;
    font-size: 30px;
    line-height: 70px;
    color: var(--second-text-color);
}
