
.canvas-component-modal .ant-modal-body{
  padding-top:0 !important;
  padding-bottom: 0 !important;
}

.canvas-component-modal-footer-content{
  padding: 0 0 10px;
  display: flex;
  align-items: center;
  gap: 16px;
  justify-content: center;
}

.canvas-component-modal-save-btn{
  background-color: var(--primary-color);
}

.core-box-prepaparing-size-buttons{
  display: flex;
  align-items: end;
  gap: 5px;
}

.core-box-prepaparing-size-buttons > div{
  border-radius: 50%;
  background-color: rgba(255, 255, 0, 0.5);
  cursor: pointer;
  transition: all .2s ease-in-out;
  border: 1px solid transparent;
}

.core-box-prepaparing-size-btn-lg{
  width: 35px;
  height: 35px;
}

.core-box-prepaparing-size-btn-medium{
  width: 28px;
  height: 28px;
}

.core-box-prepaparing-size-btn-sm{
  width: 18px;
  height: 18px;
}

.core-box-prepaparing-size-btn-active{
  border-color: var(--main-text-color) !important;
}