.notifications-all-block-top-skeleton{
  margin-bottom: 24px;
}

.notifications-all-block-top-skeleton .ant-skeleton-input{
  height: 45px;
  width: 100%;
  border-radius: var(--border-radius) !important;
}

.notifications-all-block-top-skeleton .ant-skeleton-element{
  width: 100% !important;
}

.notifications-all-block-time-container{
  margin-bottom: 24px;
}

.notifications-all-block-weeks{
  display: flex;
  align-items: center;
  gap: 12px;
}

.notifications-all-block-time-container{
  display: flex;
  align-items: center;
  gap: 20px;
}

.notifications-all-block-time-select-block{
  display: flex;
  align-items: center;
  gap: 8px;
}

.notifications-all-block-time-select-block .ant-select{
  width: 80px;
  border:1px solid var(--gray-color);
  border-radius: var(--border-radius) !important;
}

.notifications-all-block-week-day{
  width: 45px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  color:var(--main-text-color);
  border:1px solid var(--gray-color);
  border-radius: 50%;
  cursor:pointer;
}

.active-week-day{
  background-color: var(--primary-color);
  border-color: var(--primary-color);
  color:var(--white-color);
}

