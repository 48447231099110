.rdw-dropdown-selectedtext {
    display: flex;
    position: relative;
    height: 100%;
    align-items: center;
    padding: 0 5px;
    font-family: 'Gowun Dodum', sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 29px;
    color: #364f6b;
    cursor: pointer;
}
.rdw-editor-main {
    padding: 5px;
    line-height: 1.1;
}

.public-DraftStyleDefault-block {
    margin: 0;
}

.home-fontFamily {
    color: var(--second-text-color);
}

.scheduleDescription__subtask {
    margin-top: 18px;
    margin-bottom: 30px;
}