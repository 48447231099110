.task-name-edit-block{
    display: grid;
    align-items: center;
    grid-template-columns: auto 1fr;
    gap: 5px;
}

.task-name-edit-top {
    gap: 7px;
    display: flex;
    font-size: 14px;
    font-weight: 400;
    line-height: 29px;
    align-items: center;
    color: var(--main-text-color);
}

.scheduler__clearSpace {
    margin-top: 7px;
    background: #fff;
}

.taskEditSpace__clipboard {
    border-style: none;
    background: transparent;
    cursor: pointer;
    height: 24px;
}

.task-name-edit-popover{
    height: 24px;
}

.task-name-edit__skeleton{
    height: 30px !important;
    width: 100% !important;
    border-radius:  var(--border-radius) !important;
}