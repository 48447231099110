.custom-group-wrapper {
  //common styles for all feature
  .custom-group-select-input--normal {
    height: 29px;
    & .select-input-block--optionText, .select-input-block--optionText {
      font-size: 14px !important;
    }
    & .select-input-block--input{
      font-size: 14px !important;
      color:var(--main-text-color) !important;
      padding: 0 5px 0 4px;
    }
    & > div:nth-child(1){
      min-width: 36px !important;
    }
  }
  .custom-group-select-input--small {
    height: 18px;
    & .select-input-block--optionText, .select-input-block--optionText {
      font-size: 9px;
    }

    & .select-input-block--input{
      padding: 0 5px 0 4px !important;
    }
    & .ant-input, & .select-input-block--input{
      font-size: 10px !important;
      color:var(--main-text-color) !important;
    }
  & > div:nth-child(1){
    min-width: 32px !important;
  }
  }

  & .ant-select{
    background-color: transparent !important;
  }
}

.custom-group-top-input-block {
  display: grid;
  grid-template-columns: 1fr 25px;
  column-gap: 20px;
  align-items: center;

  & > svg{
    transform: translateX(-9px);
  }
}

.custom-group-columns {
  margin-top: 10px;
  display: grid;
  grid-template-columns:37% 41% 22%;
  border-bottom: 1px solid var(--placeolder-text-color);

  & > div {
    padding: 0 0 10px 10px;
  }

  & > div:nth-child(1) {
    padding-left: 0;
    padding-right: 10px;
  }

  & > div:nth-child(2) {
    border-right: 1px solid var(--placeolder-text-color);
    border-left: 1px solid var(--placeolder-text-color);
    padding-right: 10px;
  }

  & svg {
    flex-shrink: 0;
  }
}

.custom-group-parameters-name-top {
  margin-bottom: 10px;
  display: flex;
  gap: 5px;
  justify-content: space-between;

  & > span {
    max-width: 195px;
  }
}

.custom-group-parameters-list {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.custom-group-parameters-list-item {
  display: flex;
  align-items: center;
  gap: 15px;
  cursor: pointer;

  & > div {
    width: 13px;
    height: 13px;
    border-radius: 50%;
  }

  &__orange {
    background-color: var(--light-orange);
  }

  &__gray {
    background-color: var(--light-gray);
  }

  &__green {
    background-color: var(--light-green2);
  }

  &__active {
    text-decoration: underline;
  }
}

.custom-group-add-group-wrapper{
  margin-top: 10px;
  display: grid;
  grid-template-columns: 405px 35px 1fr;

  & .ant-btn{
    height: 45px;
    border-radius: var(--border-radius);
    background-color: var(--gray-color);
  }

  .custom-group-add-group-input-field{
    width: 405px;
    box-sizing: border-box;
    height: 100%;
    padding: 2px 4px;
    display: grid;
    grid-template-columns: repeat(auto-fill, 60px);
    grid-auto-rows: 40px;
    gap: 6px;
    row-gap: 4px;
    cursor:pointer;
    border-radius: var(--border-radius);
    border: 1px dashed rgba(0, 0, 0, 0.20);
    background: rgba(217, 217, 217, 0.00);
  }
  .custom-group-add-group-text-field{
    height: 100%;
    padding: 2px 4px;
    cursor:pointer;
    border-radius: var(--border-radius);
    border: 1px dashed rgba(0, 0, 0, 0.20);
    background: rgba(217, 217, 217, 0.00);
    display:flex;
    align-items: center;
    justify-content: center;
  }

  .custom-group-add-group-item{
    padding: 3px;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    flex-shrink: 0;
    border-radius: var(--border-radius);
    border: 2px solid transparent;

    //justify-content: flex-end;
    justify-content: space-between;

    &[data-group='DROPDOWN']{
      background-color: var(--light-orange);
    }
    &[data-group='VALUE']{
      background-color: var(--light-gray);
    }
    &[data-group='SET_OF_PAIRS']{
      background-color: var(--light-green2);
    }

    & > svg{
      margin-right: 5px;
      cursor: pointer;
    }
  }
  .custom-group-add-group-item--active{
    background-color: var(--success-field-bg) !important;
    border: 2px solid transparent;
  }

  .custom-group-add-group-item--error{
    border: 2px solid var(--error-text-color);
  }

  .custom-group-add-group-icon{
    margin: 0 20px 0 5px;
    cursor: pointer;
    transform: translateY(3px);
  }

  .custom-group-add-group-icon--disabled{
    cursor: not-allowed;
  }

  .add-custom-group-wrapper{
    display: flex;
    justify-content: flex-end;
  }
}

.custom-group-groups-parameters-item{
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  cursor: pointer;
}

.custom-group-groups-parameters-item > div{
  max-width: 100px;
}

.custom-group-groups-parameters-item--active{
  & > div:first-child{
    text-decoration: underline;
  }
}

.custom-group-groups-parameters-add-icon--disabled{
  cursor: not-allowed;
  opacity: .6;
}

.add-custom-group-button span{
  color:var(--main-text-color);
  font-size: 14px;
  line-height: 20px;
}

.custom-group-groups-parameters-item--error{
  color:var(--error-text-color);
}

