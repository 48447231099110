.equipment {
    width: 100%;
    background: #e9eff3;
}

.equipment__container {
    display: grid;
    grid-template-columns: 322px 1fr;
}

.equipment__entitiesSpace {
    padding: 0 24px 0 0;
    /*background: #e9eff3;*/
    /*border: 2px solid;*/
}
