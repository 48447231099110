
.person__img{
  width: 30px;
  height: 30px;
  border-radius: 50%;
  object-fit: cover;
}

.person-name-phone{
  background: var(--mint-color);
  width: 30px;
  height: 30px;
  border-radius: 50%;

  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 20px;
}

.person-name{
  width: 210px;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow-wrap: break-word;
  overflow: hidden;
}

