.coreStudySetting-pyrolysisSetting-files {
    margin-top: 20px;
}

.coreStudySetting-pyrolysisSetting-top {
    display: flex;
    gap: 10px;
}

.coreStudySetting-pyrolysisSetting-top .ant-btn,
.coreStudySetting-pyrolysisSetting-file-buttons .ant-btn {
    border-radius: var(--border-radius);
}

.coreStudySetting-pyrolysisSetting-files {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.coreStudySetting-pyrolysisSetting-file {
    /* max-width: 510px; */
    display: grid;
    align-items: center;
    grid-template-columns: 400px 1fr;
    column-gap: 5px;
}

.coreStudySetting-pyrolysisSetting-file-buttons {
    display: grid;
    column-gap: 20px;
    align-items: center;
    grid-template-columns: 115px auto auto auto;
}

.coreStudySetting-pyrolysisSetting-modal .ant-modal-body {
    padding-top: 0 !important;
}



.upload-exel-input {
    opacity: 0;
    height: 0;
    width: 0;
    position: absolute;
}

.upload-exel-btn {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.coreStudySetting-pyrolysisSetting-file-download {
    margin-top: 0;
}

.coreStudySetting-pyrolysisSetting-file-download svg {
    transform: translateX(6px);
}

/* .coreStudySetting-pyrolysisSetting-modal .ant-modal, */
.coreStudySetting-pyrolysisSetting-modal .ant-modal-body {
    /* height:900px !important; */
    height: 100% !important;
}