.share-project-input-wrapper{
  position: relative;
  z-index: 100;
  display: grid;
  column-gap: 10px;
  grid-template-columns: 1fr 160px;

}

.share-project-scrollbar{
  .simplebar-mask {
    width: 100% !important;
  }
}

.share-project-users-list{
  margin-top: 10px;
  padding: 0 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;

  .share-project-users-item{
    display: grid;
    grid-template-columns: 1fr 150px;
    align-items: center;
    .member-status-select{
      border: 2px solid var(--gray-bg-color);
      border-radius: 0 var(--border-radius) var(--border-radius) 0;
    }

    .memberItem__img{
      width: 22px;
      height: 22px;
    }

    .membersSelect__bottom-item-text{
      font-size: 14px;
    }
  }
  .membersSelect__bottom-item:hover{
    background: transparent !important;
  }

  .membersSelect__bottom-item {
    align-items: center;
    & span{
      width: 100%;
      padding: 3px 9px;
      border-radius: var(--border-radius);
      transition: all .2s ease-in-out;
      &:hover{
        background:var(--main-phone);
      }
    }
  }
}

.share-project-tags-select{
  border: 3px solid var(--gray-bg-color);
  border-radius: var(--border-radius) 0 0 var(--border-radius);
}

.share-project-copy{
  width: 100%;
  padding: 7px 12px;
  display: inline-flex;
  gap: 10px;
  align-items: center;
  justify-content: space-between;

  font-size: 16px;
  border-radius: var(--border-radius);
  background: var(--blue-primary);

  & > span{
    color:var(--white-color);
  }

  & .select-wrapper{
    //width: 137px !important;
    background: white !important;
    border-radius: var(--border-radius) !important;
  }
}

.member-status-skeleton{
  position: absolute;
  top: 40px;

  & li{
    width:100% !important;
    height: 10px !important;
  }
}

.share-project-copy-btn{
  width: 149px;
  border-style: none;
  background-color: transparent;
  outline: none;
  text-align: left;
}

.share-project-send-button{
  height: 37px !important;
  border-radius: var(--border-radius) !important;
  width: 100px;
  & span{
    font-size: 16px !important;
    color:var(--main-text-color)
  }
}

.share-project-invite-block{
  width: 160px;
  position: absolute;
  right: 0;
  top: 48px;

}

.share-project-item-select{
  width: 150px;
}

.shareProject-copylink-text{
  width: 138px;
  padding: 0 11px;
  background: var(--white-color);
  border-radius: var(--border-radius);
}

.share-project-users-item > span{
  padding-left: 8px;
}

.share-project-input-wrapper .ant-select-selection-search-input{
  font-size: 14px !important;
  color:var(--main-text-color)
}

.copyLink-skeleton{
  margin-top: 5px;
  width: 100% !important;
  border-radius: var(--border-radius);
  height: 43px !important;
}

.share-project .member-item-phone {
   background-color: var(--main-text-color);
}