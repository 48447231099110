/*strangeNanList*/
.strangeNanList{
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.strangeNanList_label{
  display: flex;
  align-items: center;
  gap: 5px;
  color:var(--main-text-color);
  font-size: 14px;
}

.strangeNanList_label svg{
  width: 21px;
  height: 21px;
}

.strangeNanList textarea{
  height: 75px;
  width: 100%;
  padding: 3px 7px;

  border: 0.4px solid rgba(97, 97, 97, 0.3);
  border-radius: var(--border-radius);
  font-size: 14px;
  color:var(--main-text-color);
  resize:none;
  outline: none;
}

.missingDataMessage{
  color:var(--error-text-color);
  font-size: 14px;
}