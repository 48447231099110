.app-bottom {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0px;
    height: 1px;
}

.font-30-normal {
    font-size: 30px;
    line-height: 70px;
    color: var(--second-text-color);
}

.font-25-normal {
    font-style: normal;
    font-weight: 400;
    font-size: 25px;
    line-height: 36px;
    color: var(--main-text-color);
}

.font-22-normal {
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 36px;
    color: var(--main-text-color);
}

.font-8-normal {
    font-style: normal;
    font-weight: 400;
    font-size: 8px;
    color: var(--main-text-color);
}

.font-10-normal {
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 13px;
    color: var(--main-text-color);
}

.font-12-normal {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 17px;
    color: var(--main-text-color);
}

.font-20-normal {
    color: var(--main-text-color);
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 29px;
}

.font-18-normal {
    font-style: normal;
    color: var(--main-text-color);
    font-weight: 400;
    font-size: 18px;
    line-height: 26px;
}

.font-16-normal {
    font-style: normal;
    color: var(--main-text-color);
    font-weight: 400;
    font-size: 16px;
    /*line-height: 32px;*/
}

.font-15-normal {
    font-style: normal;
    color: var(--main-text-color);
    font-weight: 400;
    font-size: 15px;
    line-height: 27px;
}

.font-14-normal {
    color: var(--main-text-color);
    font-size: 14px;
    line-height: 20px;
}

.font-14-normal-imp {
    color: var(--main-text-color) !important;
    font-size: 14px !important;
}

.font-error {
    color: var(--error-text-color);
    font-size: 14px;
}

.font-primary {
    color: var(--primary-color);
}

.font-link {
    color: var(--primary-color);
    text-decoration: underline;
    cursor: pointer;
}

.font-white {
    color: var(--white-color);
}

.font-bold {
    font-weight: 600;
}

.line-heignt-24 {
    line-height: 24px;
}
.line-heignt-30 {
    line-height: 30px;
}

/*flex*/
.row-flex-sb {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.column-flex-sb {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: space-between;
}

.column-flex-5 {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    gap: 5px;
    justify-content: space-between;
}

.row-flex-32 {
    display: flex;
    align-items: center;
    gap: 32px;
}

.row-flex-20 {
    display: flex;
    align-items: center;
    gap: 20px;
}

.row-flex-10 {
    display: flex;
    align-items: center;
    gap: 10px;
}

.row-flex-8 {
    display: flex;
    align-items: center;
    gap: 8px;
}

.row-flex-5 {
    display: flex;
    align-items: center;
    gap: 5px;
}

.column-flex-16 {
    display: flex;
    flex-direction: column;
    gap: 16px;
}
.column-flex {
    display: flex;
    flex-direction: column;
}

.flex-c-c {
    display: flex;
    align-items: center;
    justify-content: center;
}

.flex-c-sb {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.flex-shrink-0 {
    flex-shrink: 0;
}

.row-grid-10 {
    display: grid;
    gap: 10px;
}

.flex {
    display: flex;
}

.flex-justify-end {
    display: flex;
    align-items: center;
    justify-content: end;
}

.flex-align-center {
    display: flex;
    align-items: center;
}

.cursor-pointer {
    cursor: pointer;
}

.cursor-not-allowed {
    cursor: not-allowed !important;
}

/*width*/
.w-100p {
    width: 100%;
}

.max-w-200 {
    max-width: 200px;
}

.max-w-750 {
    max-width: 750px;
}

.max-w-700 {
    max-width: 700px;
}

.w-15 {
    width: 15px;
}

.w-13 {
    width: 15px;
}

/*height*/
.h-15 {
    height: 15px;
}
.h-13 {
    height: 15px;
}

.h-37 {
    height: 37px;
}

.h-30 {
    height: 30px;
}

.h-100p {
    height: 100%;
}
.h-fit {
    height: fit-content;
}
/*padding*/
.pt-5 {
    padding-top: 5px;
}

.pt-10 {
    padding-top: 10px;
}

.p-5 {
    padding: 5px;
}

.p-8 {
    padding: 8px;
}
.p-10 {
    padding: 10px;
}

.pr-5 {
    padding-right: 5px;
}

.pl-5 {
    padding-left: 5px;
}

.pl-8 {
    padding-left: 8px;
}

.pl-14 {
    padding-left: 14px;
}

/*margin*/
.mt-60 {
    margin-top: 60px;
}

.mt-54 {
    margin-top: 54px;
}

.mt-40 {
    margin-top: 40px;
}

.mt-35 {
    margin-top: 35px;
}

.mt-30 {
    margin-top: 30px;
}

.mt-20 {
    margin-top: 20px;
}

.mt-18 {
    margin-top: 18px;
}

.mt-15 {
    margin-top: 15px;
}

.mt-10 {
    margin-top: 10px;
}

.mt-8 {
    margin-top: 8px;
}

.mt-3 {
    margin-top: 3px;
}

.mb-10 {
    margin-bottom: 10px;
}

.mb-8 {
    margin-bottom: 8px;
}

.mb-5 {
    margin-bottom: 5px;
}

.m-0-auto {
    margin: 0 auto;
}

.br-30 {
    border-radius: 30px;
}

.br-5 {
    border-radius: 5px;
}

.br-default {
    border-radius: var(--border-radius);
}

.border-gray {
    border: 1px solid #e1e1e1;
}

/*transition*/
.transition1 {
    transition: all 0.1s ease-in-out;
}

.transition2 {
    transition: all 0.2s ease-in-out;
}

.transition3 {
    transition: all 0.3s ease-in-out;
}

/*text edit*/
.text-underline {
    text-decoration: underline;
}

.break-word {
    word-break: break-word;
}

.font-main-text {
    color: var(--main-text-color);
}

.text-left {
    text-align: left;
}

.text-right {
    text-align: right;
}

.text-center {
    text-align: center;
}

.cursor-not-allowed {
    cursor: not-allowed;
}

.cursor-zoom-in {
    cursor: zoom-in;
}

.hidden {
    opacity: 0;
    height: 0;
    width: 0;
    line-height: 0;
    overflow: hidden;
    padding: 0;
    margin: 0;
}

.pos-relative {
    position: relative;
}

.absolute-loading {
    min-height: 100px;
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 100;
}

.rotate-90d {
    transform: rotate(90deg);
}

.rotate-270d {
    transform: rotate(270deg);
}

.dotted-text-100 {
    max-width: 100px;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow-wrap: break-word;
    overflow: hidden;
}

.dotted-text-120 {
    max-width: 120px;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow-wrap: break-word;
    overflow: hidden;
}

.dotted-text-140 {
    max-width: 140px;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow-wrap: break-word;
    overflow: hidden;
}

.dotted-text-150 {
    max-width: 150px;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow-wrap: break-word;
    overflow: hidden;
}

.dotted-text-180 {
    max-width: 180px;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow-wrap: break-word;
    overflow: hidden;
}

.dotted-text-190 {
    max-width: 190px;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow-wrap: break-word;
    overflow: hidden;
}
.dotted-text-200 {
    max-width: 200px;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow-wrap: break-word;
    overflow: hidden;
}

.dotted-text-clamp-2 {
    -webkit-line-clamp: 2;
}

.dotted-text-210 {
    max-width: 210px;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow-wrap: break-word;
    overflow: hidden;
}
.dotted-text-230 {
    max-width: 230px;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow-wrap: break-word;
    overflow: hidden;
}

.dotted-text-250 {
    max-width: 250px;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow-wrap: break-word;
    overflow: hidden;
}

.bg-white {
    background-color: var(--white-color);
}

.title {
    font-weight: 400;
    line-height: 29px;
    color: var(--main-text-color);
}

.input-success {
    background: var(--success-field-bg) !important;
    border: 1px solid var(--green-success) !important;
}

.input-error {
    border-color: var(--error-text-color) !important;
    background: rgb(251, 218, 226) !important;
}

.hide-scrollbar {
    overflow: scroll;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* IE и Edge */
}

.hide-scrollbar::-webkit-scrollbar {
    display: none; /* Chrome, Safari и Webkit-браузеры */
}

.item-hover {
    transition: all 0.2s ease-in-out;
    border-radius: var(--border-radius);
}
.item-hover:hover {
    background-color: var(--selected-menu-item);
}

.tab-item {
    font-size: 20px;
    color: var(--main-text-color);

    transition: var(--transition);
    cursor: pointer;
}

.tab-item--active {
    color: var(--primary-color);
}

.display-none {
    visibility: hidden;
}

.menu-item--selected {
    background-color: var(--selected-menu-item);
}
