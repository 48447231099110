.roadMap {
    width: 100%;
    padding: 10px 0 0 10px;

    background: var(--main-phone);
    overflow: hidden;
}

.roadMap-top {
    display: flex;
    gap: 50px;
}

.roadmap-accordion .scheduler-accordion__bottom{
    margin-left:0;
}

.roadMap-workSpace{
    margin-top: 10px;
    position: relative;
}

.roadMap-workSpace-top{
    display: grid;
    grid-template-columns: 758px 1fr;
}

.roadMap-workSpace-bottom{
    width: 100%;
    max-height: calc(100vh - 390px);
    overflow: hidden;
    position: relative;
}

.roadMap-workSpace-bottom-collapsed{
    max-height: calc(100vh - 216px);
}

.roadMap-workSpace-bottom-wrapper{
    height: 100%;
    /* max-height: calc(100vh - 330px); */
    max-height: inherit;

    display: grid;
    grid-template-columns: 758px 1fr;
    
    overflow-y:auto ;

}



.roadMap-workSpace-top .roadMap-workSpace-column:first-child{
    background-color: #e9eff3;
}

.roadMap-workSpace-column:first-child .roadMap-tasks, .roadMap-workSpace-column:first-child .roadMap-calendar-top{
    border-right:1px solid var(--field-border);
}

.roadMap-calendar-top{
    position: relative;
}

.roadMap-calendar-top::before{
    content: '';
    width: 726px;
    height: 8px;
    background-color: #fff;
    position: absolute;
    bottom: -9px;
    left: 32px;
}

.roadMap-workSpace-top .roadMap-workSpace-column:last-child .roadMap-workSpace-column-top{
    height: 100%;
    padding: 0;
}
.roadMap-workSpace-top .roadMap-workSpace-column:last-child .roadmap-calendar-group-items{
    height: 26px;
}

.roadMap-workSpace-bottom-wrapper .roadMap-workSpace-column:first-child{
    padding-left: 20px;
}

.roadMap-workSpace-column:last-child .roadMap-workSpace-column-top{
    padding-bottom: 8px;
    position: relative;
}

.roadMap-workSpace-column:last-child .roadMap-workSpace-column-top{
    padding-bottom: 8px;
}

.roadMap-workSpace-column:last-child .roadMap-workSpace-column-top .roadmap-calendar-item {
    background-color: var(--grey-phone-color);;
}

.calendar-items .roadmap-calendar-group{
    min-height: 27px;
}


.roadMap-top-item:first-child{
    display: flex;
    gap: 14px;
}

.roadMap-top-icons{
    padding-top: 24px;
    
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.roadMap-calendar-top {
    height: 42px;
}

.roadMap-items-top {
    width: 100%;
    border-radius: var(--border-radius) 0 0 0;
}

.roadMap-items-top-columns {
    display: grid;
    align-items: center;
    grid-template-columns: 32px 76px 70px 229px 115px 115px 120px;
}

.roadMap-items-top div {
    width: 100%;
    height: 100%;
}

.roadMap-items-top-columns > div {
    display: flex;
    align-items: center; 
    justify-content: center;

    text-align: center;
    color: var(--main-text-color);
    font-size: 13px;
    background-color: var(--grey-phone-color);
}

.roadMap-items-top-columns > div:first-child{
    height: fit-content;
    background-color: var(--main-phone);
}
.roadMap-items-top-columns > div:nth-child(2){
    border-radius: var(--border-radius) 0 0 0 ;
}

.roadMap-tasks .pagination_paginator__3ALJU {
    background-color: transparent;
}

.roadMap-top-item-detalization{
    max-width: 220px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    
}

.roadMap-pagination > div > div{
    justify-content: flex-start;
}

@media (max-width:1500px) {
    .roadMap-top{
        flex-direction: column;
        gap: 12px;
    }

    .roadMap-top-item-detalization{
        margin-left: 39px;
        flex-direction: row;
    }
}


