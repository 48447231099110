.custom-group-parameter{
    width: 100%;
    text-align: center;
}

.custom-group-parameter span{
    color:var(--main-text-color);
}
.custom-group-parameter-title{
    font-size: 12px;
    color:var(--main-text-color);
}

.custom-group-parameter--double-title{
    display: flex;
    justify-content: space-around;
    align-items: center;
}

/*CustomGroupParameters.NUMERIC_VALUE*/
.custom-group-parameter--input{
    height: 29px !important;
    width: 100%;
    min-width: 150px;
    font-size: 16px;
    border: 1px solid var(--border-gray) !important;
    color:var(--main-text-color);
    background: #e9eff3;
    border-radius: var(--border-radius);
}

/*CustomGroupParameters.DROPDOWN*/
.custom-group-parameter--select{
    height: 29px;
    width: 100%;
    min-width: 200px;
    font-size: 16px;
    border: 1px solid var(--border-gray);
    color:var(--main-text-color);
    /*background: #e9eff3;*/
    border-radius: var(--border-radius);
}

/*CustomGroupParameters.SET_OF_PAIRS*/
.custom-group-parameter--double-input-wrapper{
    width: 100%;
    min-width: 300px;
    display: flex;
    align-items: center;
}

.custom-group-parameter--input-first{
    border-radius: var(--border-radius) 0 0 var(--border-radius) !important;
}

.custom-group-parameter--input-second{
    border-radius: 0 var(--border-radius) var(--border-radius) 0 !important;
    transform: translateX(-1px);
}

.custom-group-parameter--input::placeholder{
    color:var(--second-text-color) !important;
}

.custom-group-parameter--error{
    border-color: red !important;

}