.well-mode{
    padding: 20px;
}

.well-mode h1{
    text-align: center;
}

.well-mode-list{
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 10px;
}

.well-mode-list > div{
    cursor: pointer;
    width: 40px;
    height: 40px;
    background-color: antiquewhite;
    display: flex;
    align-items: center;
    justify-content: center;
}

.well-mode-list--active{
    background-color: brown !important;
}

.well-mode-content{
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 200px;
}

.well-mode-content > div{
    margin-top: 10px;
    width: 100%;
    height: 500px;
    display: flex;
    justify-content: center;
    flex-shrink: 0;
}

.well-mode-graphics{
    display: flex;
    gap: 10px;
}

.well-mode-graphics > div{
    margin-right: 10px;
}

.well-mode-content .header{
    height: 18px !important;
}

.well-mode-content .header text{
    transform: translateX(26px);
}

.svg_holder .header text:nth-child(4){
    /*display: none;*/
}

/* .tick:last-child text{*/
/*    display: none;*/
/*}*/
.well-mode-graphics{
    display: flex;
}
