.tagsDropdown {
    position: relative;
    z-index: 2;
}
.tagsDropdown-top {
    padding: 2px 20px 2px 8px;
    width: 100%;
    border-radius: var(--border-radius);
    background: transparent;
    border: 1px solid var(--field-border);
    position: relative;
    z-index: 2;
}

.tagsDropdown-top-tagSpace .ant-tag {
    height: 22px !important;
    color: var(--main-text-color) !important;
}

.tagsDropdown-inputSpace .ant-input {
    width: 96px !important;
}
.tagsDropdown-btn {
    width: 25px;

    position: absolute;
    right: 0;
    top: 50%;

    outline: none;
    border-style: none;
    transform: translateY(-50%);
    border-radius: 0 var(--border-radius) var(--border-radius) 0;
}

.tagsDropdown-btn svg {
    display: flex;
}

.tagsDropdown-icon {
    transition: all 0.3s ease-in-out;
    position: relative;
    z-index: -1;
}

.tagsDropdown-bottom {
    margin-top: 8px;
    width: 191px;
    /*height: 125px;*/
    background: #ffffff;

    border-radius: 1px;
    transition: all 0.4s ease-in-out;
    position: relative;
    z-index: 0;
}
.tagsDropdown-bottom-inner {
    transition: all 0.3s ease-in-out;
}

.tagsDropdown-panel {
    height: 21px;
    background: rgba(252, 255, 105, 0.3);
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    /*transition: all .1s ease-in-out;*/
}

.tagsDropdown-panel-item {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: var(--second-text-color);
    cursor: pointer;
    transition: all 0.3s ease-in-out;
}
.tagsDropdown-panel-current {
    position: relative;
    transition: all 0.3s ease-in-out;
}

.tagsDropdown-panel-current::after {
    content: '';
    height: 1px;
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    transition: all 0.3s ease-in-out;
}

.scheduler-tags-modal .ant-modal-body {
    padding-top: 0 !important;
}

.scheduler-tags-modal {
    width: 700px !important;
    min-height: 400px !important;
}
.scheduler-tags-modal .ant-modal-content {
    min-height: 400px !important;
}

.scheduler-tags-modal .ant-tabs-nav-wrap {
    display: block !important;
}

.scheduler-tags-modal .ant-tabs-nav-list .ant-tabs-tab {
    width: 100% !important;
    justify-content: center !important;
}

.scheduler-tags-modal .ant-tabs-nav-operations {
    display: none !important;
}

.scheduler-tags-tabs .ant-tabs-tab + .ant-tabs-tab {
    margin: 0 !important;
}

.tagsDropdown-add-tag-btn-wrapper {
    height: 22px;

    display: flex;
    align-items: center;
    gap: 0;

    color: var(--main-text-color);
}

.tagsDropdown-add-tag-btn-wrapper span {
    height: 20px;
}

.tagsDropdown__input {
    width: fit-content !important;
    height: 22px !important;
}

.selected-tag-wrapper {
    margin: 2px 0;
    display: inline-block;
}

.selected-tag {
    margin: 2px;

    display: flex;
    align-items: center;
    cursor: pointer;
}
.selected-tag span {
    line-height: 20px;
    font-size: 12px;
    color: var(--main-text-color);
}

.selected-tag--archived span:first-child {
    color: var(--error-text-color);
}

.tags-tabs-top {
    margin-bottom: 20px;
    
    display: grid;
    grid-template-columns: repeat(3, 150px);
    column-gap: 10px;
}
