.prioritySelect__option{
    display: flex;
    align-items: center;
    gap: 8px;
}

.priority-select__wrapper{
    width: 100%;
}
.priority-select__wrapper svg{
    flex-shrink: 0;
}

.prioritySelect__option-priority{
    height: 20px;
    min-width: 20px;
    border-radius: var(--border-radius);
}

.prioritySelect__option-priority-low{
    background-color: var(--low-priority);
}
.prioritySelect__option-priority-medium{
    background-color: var(--medium-priority);
}
.prioritySelect__option-priority-important{
    background-color: var(--important-priority);
}
.prioritySelect__option-priority-critical{
    background-color: var(--critical-priority);
}

.prioritySelect__option-span{
    font-size: 14px;
}