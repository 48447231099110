.notifications{
    padding: 20px 33px;
    background: var(--white-color);
}

.notifications-active-tab{
    color: var(--primary-color);
    text-decoration: underline;
}

.notifications-all-block .table{
    border:1px solid var(--gray-color);
    border-radius: var(--border-radius);
}

.notifications-all-block .table-top{
    padding: 0  15px 0;
    display: grid;
    grid-template-columns: auto 59% 21.7% 21.5%;
    border-radius: var(--border-radius);
}

.notifications-all-block .table-top > span:nth-child(3),
.notifications-all-block .table-top > span:nth-child(4){
    border-left:1px solid var(--gray-color);
    padding: 16px 10px;
    text-align: center;
}

.notifications-all-block .table-top > span:nth-child(3){
    margin-left: 1px;
}

.notifications-all-block .table-top > span:nth-child(2){
    padding: 13px;
}

.notifications-all-block-row-item{
    display: grid;
    grid-template-columns: 59% 20% 20%;
}

.notifications-all-block ul{
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    border-top:1px solid var(--gray-color);
}

.notifications-all-block-row-item > div:nth-child(1){
    padding: 10px 5px 10px 26px;
}

.notifications-all-block-row-item > div:nth-child(2),
.notifications-all-block-row-item > div:nth-child(3)
{
    margin-left: 2px;
    padding: 10px;
    text-align: center;
    border-left:1px solid var(--gray-color);
}

.notifications-all-block-row-item > div:nth-child(2){
    margin-left: -2px;
}

