.subtask-item {
    padding: 0 8px;
    height: 38px;

    overflow: hidden;
}

.subtask-item:last-child {
    margin-bottom: 0;
}

.subtask-item:hover {
    background: rgba(217, 217, 217, 0.2);
    border-radius: var(--border-radius);
}

.subtasks-list{
    padding: 0;
    margin-bottom: 0;
    max-height: 250px;
    overflow-y: auto;
}