.select-input-block {
  height: 100%;
  display: flex;
  align-items: center;
  background: #E7EFF3;
  border: 0.5px solid var(--green-success);
  border-radius:var(--border-radius);

  & > div:nth-child(1){
    min-width: 34px !important;

    & .ant-select-arrow{
      right: 0px !important;
    }
  }
  & > div:nth-child(2){
    width: 60% !important;
  }

  .ant-select-selector{
    padding: 0 !important;
  }
}

.select-input-block--select {
  font-size: 14px;
  color: var(--main-text-color);

  .select-input-block--optionText {
    font-size: 14px;
    color: var(--main-text-color);

    & div {
      font-size: 14px;
    }
  }

}

.dropdownSelect {
  .ant-select-dropdown {
    min-width: 185px !important;
  }
}

.select-input-block--input {
  width: 100%;
  height: 100%;
  padding: 0 5px 0 5px;

  background: transparent;
  border-style: none;
  outline: none;
  color: var(--main-text-color);
  font-size: 14px;
}

/*tags type*/

.select-input-block .select-input-block-tags{
  min-width: 74% !important;
}

 .select-input-block-tags  .ant-select-selector{
  background: transparent !important;
  border-style: none !important;
}

 .select-input-block--tag-item{
   display: flex;
   align-items: center;
   justify-content: space-between;
   padding: 0 7px;
   & > svg{
     flex-shrink: 0;
   }
 }



