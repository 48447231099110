.userMenuPanel {
    width: 30px;
    height: 30px;
    margin-right: 10px;

    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    border-radius: 50%;
    position: relative;
    z-index: 100000000000000000;
    overflow: hidden;
}
