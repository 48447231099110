.ui-button{
    height: 30px;

    display: flex !important;
    align-items:center !important;
    justify-content: center !important;
    
    font-size: 14px !important;
    color:var(--main-text-color);
}

.ant-btn-primary{
    background-color:var(--blue-primary) !important;
    color:var(--main-text-color) !important;
}

.ant-btn-default:hover span{
    opacity: .5;
}

.ant-btn-primary:hover{
    color:var(--white-color) !important;
}

.ant-btn {
    border-radius: var(--border-radius) !important;
}

.ant-btn:focus,
.ant-btn:hover {
    color: var(--main-text-color);
    border-color: var(--main-text-color);
}

button:disabled {
    cursor: initial;
    opacity: 0.6;
}