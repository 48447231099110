.roadmap-filter {
    display: grid;
    column-gap: 8px;
    row-gap: 8px;
    grid-auto-flow: row;
    grid-template-columns: repeat(4, 210px);
}

.roadmap-filter-field-wrap {
    display: flex;
    flex-direction: column;
    gap: 0;
}

.roadmap-filter-member-select-option {
    padding: 0;
    left: 0;
    height: 100%;
}


.roadmap-filter-member-select-option .membersSelect__bottom-item-text {
    font-size: 14px;

    max-width: 150px;
    text-overflow: ellipsis;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow-wrap: break-word;
    overflow: hidden;
}

.ant-select-selection-item .roadmap-filter-member-select-option span{
    padding-left: 0 !important;
    max-width: 145px;
    
}
.roadmap-filter-member-select-option .memberItem__img{
    align-self: center;
}

.roadmap-filter-field-label {
    font-size: 14px;
    color: var(--main-text-color);
}

.roadmap-filter-field {
    height: 100% !important;
    min-height: 30px;

    font-size: 12px;
    border: 1px solid var(--field-border);
    border-radius: var(--border-radius);
}
