.upload-button-wrapper {
}

.upload-button-wrapper input {
    opacity: 0;
    height: 0;
    width: 0;
    position: absolute;
}

.upload-button-btn {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
