.paginator {
    max-width: 100%;
    padding: 5px 0;
}
.paginatorContainer {
    display: flex;
    align-items: center;
    justify-content: space-around;
}
.pageContainer {
    display: flex;
}

.paginatorBtn {
    width: 2vw;
    background-color: transparent;
    border: none;
    cursor: pointer;
}

.paginatorBtn:disabled {
    opacity: 0.5;
}

.pageNumbersBlock {
    display: flex;
    flex-wrap: nowrap;
    margin: 0 1px;
}

.pageNumber {
    min-width: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0.2vw;
    cursor: pointer;
    padding: 1px;
    color: #364f6b;
    font-family: 'Gowun Dodum', sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 29px;
    position: relative;
}

.selectedPage {
    position: relative;
}
.selectedPage::after {
    content: '';
    width: 10px;
    height: 1px;
    background: #364f6b;
    position: absolute;
    bottom: 3px;
    left: 50%;
    transform: translateX(-50%);
    /*display: none;*/
}

.pageNumber:hover {
    opacity: 0.5;
}
.wrapper {
    display: flex;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    color: #21268f;
}
.selectWrapper {
    margin-left: 34px;
    width: 27%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-width: 180px;
}

select {
    background: #ffffff;
    border: 1px solid #eff6d5;
    width: 45px;
    height: 24px;
}
select:focus {
    outline: none;
}
