.projects__elements-cores {
    margin-top: 14px;
    height: 100%;
    width: 160px;

    display: flex;
    flex-direction: column;

    border-radius: 3px;
}

.projects__elements-coreList {
    border-radius: var(--border-radius);
    background-color: var(--white-color);
}

.projects__elements-core {
    margin-bottom: 17px;
    display: flex;

    color: var(--second-text-color);
    font-size: 14px;
    line-height: 20px;
}

.projects__elements-core:last-child {
    margin-bottom: 0;
}

.projects__elements-cores-simpleBar {
    max-height: calc(100vh - 350px);
    padding: 7px 8px;
}

.coresSimpleBar {
    background: transparent;
    padding: 0;
}

.core-item--skeleton {
    margin-top: 5px;
    border-radius: var(--border-radius);
}

.core-item--skeleton:nth-child(1) {
    margin-top: 0;
}

.core-item--skeleton .ant-skeleton-input {
    height: 34px !important;
}

.projects__elements-coreList-loading{
    background-color: transparent !important;
}
