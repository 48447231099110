.scheduler__fileAttach {
    padding: 15px 21px 70px 15px;
    max-width: 920px;
    background: #ffffff;
    border: 1px solid #b2afaf;
    border-radius: var(--border-radius);
}

.scheduler__fileAttach-inner {
    display: flex;
}

.scheduler__fileAttach-btn {
    width: 137px;
    height: 85px;
    border-radius: var(--border-radius);
    background: #ffffff;
}

.ant-upload-list {
    margin-top: 10px;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-auto-flow: row;
    gap: 60px;
}

.ant-upload-list-picture-container,
.ant-upload-list-text-container {
    width: 137px;
    /* height: 100px; */
}

.ant-upload-list-picture-card .ant-upload-list-item,
.ant-upload-list-picture .ant-upload-list-item {
    height: 85px;
    background: #ffffff;
    border: 0.4px solid #b2afaf;
    border-radius: var(--border-radius);
}

.ant-upload-list:after,
.ant-upload-list:before {
    display: none;
}

.ant-tooltip-placement-top .ant-tooltip-arrow {
    display: none;
}
.ant-tooltip-content {
    display: none;
}

/*стили для элемента upload*/
.ant-upload-span {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
}

.ant-upload-list-picture .ant-upload-list-item-name,
.ant-upload-list-text .ant-upload-list-item-name {
    height: 10px;
    font-weight: 400;
    font-size: 12px;
    color: #364f6b;
    overflow: visible;
    transform: translateY(-10px);
}

.ant-upload-list-picture .ant-upload-list-item-card-actions,
.ant-upload-list-text .ant-upload-list-item-card-actions,
.ant-upload-list-text .ant-upload-text-icon .anticon {
    position: absolute;
    top: 0;
    right: 0;
    /*opacity: 0;*/
}
.ant-upload-list-item-card-actions .anticon {
    transition: all 0.3s ease-in-out;
    opacity: 0;
}

.ant-upload-span:hover .ant-upload-list-item-card-actions .anticon {
    opacity: 1;
}

.ant-upload-list:after, .ant-upload-list:before{
    display: none !important;
}

.uploadItem__btn{
    border-style: none;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 30px;
    background-color: white;
    border-radius: var(--border-radius);
}

.uploadItem{
    margin-top: 10px;
    width: 165px;
    height: 150px;

    border:1px solid #b2afaf;
    border-radius: var(--border-radius);
    position: relative;
    text-align: center;
    box-sizing: border-box;
}

.uploadItem-btn-wrapper{
    opacity: 0;
    display: flex;
    align-items: center;
    gap: 10px;
    cursor: pointer;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    transition: all .3s ease-in-out;
}

.uploadItem:hover .uploadItem-btn-wrapper{
    opacity: 1;
}

.uploadItem__img, .uploadItem__file{
     width: 100%;
     height: 100px;
     object-fit: cover;
     border-radius: var(--border-radius) var(--border-radius) 0 0;
 }
.uploadItem__file{
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fafafa;
}

.uploadItem__name{
    margin-top: 5px;

    display: -webkit-box;
    width: 160px;
    overflow-wrap: break-word;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    color: var(--second-text-color);
    overflow: hidden;
}

.file-attach .ant-upload-drag-container{
    color:var(--main-text-color) !important;
    font-size: 14px;
}
