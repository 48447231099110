/*стили для модалки*/
.subTask__modal {
    text-align: center;
}
.subtask__modal .ant-modal-title {
    text-align: center;
    font-weight: 500;
    font-size: 18px;
    color: var(--main-text-color);
}
.subtask__modal-content {
    text-align: center;
    font-weight: 400;
    font-size: 16px;
    line-height: 29px;
    color: var(--main-text-color);
}
.subtask__modal .ant-modal-content {
    border: none;
    border-radius: var(--border-radius);
}

.subtask__modal .ant-modal-header {
    border: none;
    border-radius: var(--border-radius) var(--border-radius) 0 0;
}
.subtask__modal .ant-modal-footer {
    border: none;
    border-radius: 0 0 var(--border-radius) var(--border-radius);
}
.subTask__modal .ant-btn {
    font-weight: 400;
    font-size: 12px;

    border-radius: var(--border-radius);
}

.subtask__modal .ant-popover-content {
    padding: 4px 6px;
}
