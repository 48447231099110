:root {
    --main-text-color: #364f6b;
    --main-phone:#e9eff3;
    --second-text-color: rgba(0, 0, 0, 0.62);
    --black-text-color:#000;
    --placeolder-text-color: lightgrey;
    --dark-gray-color: rgb(188 179 179);
    --error-text-color: #ff6d6d;
    --gray-color: #E1E1E1;
    --gray-bg-color:rgba(178, 175, 175, 0.19);
    --gray-border-color:#f5f5f5;
    --white-color:#fff;
    --primary-color:#009EF5;
    --light-blue:#F4F9FB;
    --orange:#FF9F2D;
    --light-orange:rgba(1, 1, 1, 0.4);;
    --yellow:#ffddb5;
    --light-green:#A2FFA6;
    --light-green2:rgba(63, 193, 201, 0.40);
    --light-red:#FFC7C7;
    --light-gray:rgba(129, 148, 166, 0.40);
    --mint-color:#3FC1C9;
    --border-gray:#CCCCCC;
    --field-border:#d9d9d9;
    --green-success:#34CD84;
    --light-blue-success:#bae0f9;
    --bg-error:#FBDAE2;
    --success-field-bg:#E7EFF3;
    --border-error:#FF4471;
    --border-success:#abf4d1;
    --deadline-error:#DC5882;
    
    --border-radius:5px;

    --low-priority:#DEFF5B;
    --medium-priority:#FFEC43;
    --important-priority:#FF9255;
    --critical-priority:#FF4545;

    --blue-primary:#97ACF8;
    --grey-phone-color:#F5F5F5;

    --grey-icon-color:#828282;
    --grey-icon-color-disabled:#E0E0E0;
    --selected-popover-item:#8267CE;
    --selected-menu-item:#DEE5FB;
    --drag-item-phone:rgba(219, 218, 255, 0.5);

    --transition:all 0.2s ease-in-out;

    --menu-width:250px;
    --menu-collapsed-width:58px;
}