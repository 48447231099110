.all-notifications{
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.all-notifications-spin-wrap{
    margin-top: 20px;
    max-width: 320px;
    display: flex;
    justify-content: center;
}