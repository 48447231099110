.roadmap-filter-field-wrap{
    min-width: 210px;
}

.roadMap-sort-item{
    display: flex;
    gap: 5px;
    align-items: center;
}

.add-entity-icon-wrap{
    width: 22px !important;
    height: 22px !important;

    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;

    border-radius: var(--border-radius);
    border:1px solid var(--main-text-color);
    background-color: var(--white-color);
}

.roadMap-sort-item svg{
     flex-shrink: 0;
     height: 12px;
}