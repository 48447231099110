.member-item {
    width: 100%;
    height: 100%;
    padding: 2px;

    position: relative;
}

.member-item-phone {
    width: 16px;
    height: 16px;

    border-radius: 50%;
    color: var(--white-color);
    
}

.membersSelect__bottom-btn {
    margin-left: 3px;
    border-style: none;
    background: transparent;
    opacity: 0;
    transition: all 0.1s ease-in-out;
    cursor: pointer;
    position: absolute;
    top: 50%;
    right: 5px;
    transform: translateY(-50%);
    flex-shrink: 0;
}

.membersSelect__bottom-dotted-txt{
    width: 230px;
    white-space: nowrap; /* Текст не переносится */
    overflow: hidden; /* Обрезаем всё за пределами блока */
    text-overflow: ellipsis; /* Добавляем многоточие */
}

.membersSelect__bottom-item:hover .membersSelect__bottom-btn {
    opacity: 1;
    z-index: 1;
}

.cancelIcon {
    position: relative;
    z-index: -1;
}

.member-item img {
    width: 16px;
    height: 16px;

    border-radius: 50%;
    object-fit: cover;
}

.membersSelect__bottom-item-text{
    width: fit-content;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
