.custom-group-parameters{
  display: flex;
  gap: 10px;
  align-items: flex-start;
  justify-content: space-between;
}

.custom-group-parameters-wrapper{
  display: flex;
  gap: 10px;
}

.custom-group-parameters--deleteBtn{
  width: 38px;
  height: 38px;
}

.custom-group-parameters--deleteBtn svg{
  width: 100% !important;
  height: 100% !important;
}

.custom-group-parameter--double-input-wrapper{
  max-width: 200px;
  display: flex;
  align-items: center;
}

.custom-group-parameter--double-input-wrapper .coreStudyInput:nth-child(1) input{
  border-radius: var(--border-radius) 0 0 var(--border-radius);
}
.custom-group-parameter--double-input-wrapper .coreStudyInput:nth-child(2) input{
  border-radius: 0  var(--border-radius) var(--border-radius) 0;
  border-left-width:.5px;
}

.custom-group-parameter--skeleton{
  margin-top: 10px;
  width: 100% !important;
  border-radius: var(--border-radius);
}