.logs-upload .table-top{
    background: rgba(217, 217, 217, 0.2);
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: 0.2fr 1.2fr .4fr 1.0fr .4fr 1fr;
}

.logs__loading{
    height: 36px;
    position: relative;
}

.logs__loading_inner{
    width: 0;
    background: lightgreen;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    border-radius: var(--border-radius);
    color:#fff;
    font-size: 18px;
    text-align: center;
    line-height: 35px;
}

.logs__upload .ant-upload-list-picture-container, .ant-upload-list-text-container{
    height: 0 !important;
}

.logs-upload-download-message{
    display: flex;
    height: 40px;
    align-items: center;
    font-size: 18px;
    color: var(--main-text-color);
    position: relative;
}

.logs-upload-download-message-inner{
    width: 0;
    background: rgba(144, 238, 144, 0.49);
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    border-radius: var(--border-radius);
    color:var(--main-text-color);
    font-size: 18px;
    text-align: center;
    line-height: 35px;
}