.projectsMenu {
    min-height: calc(100vh - 45px);
    transition: all 0.3s ease-in-out;
    color: var(--second-text-color);
    overflow: hidden;
    transform: translateX(15%);
}

.projectsMenu__open {
    transition: all 0.3s ease-in-out;
    background: var(--gray-border-color);
    transform: translateX(0);
}


.projectsMenu__top-open {
    background: var(--gray-border-color);
    padding: 7px;
}

.projectsMenu__projectsListInner{
    padding: 0 8px;
}



/*Pads*/
.projectsMenu__Pad {
    flex-direction: column;
}

.projectsMenu__PadItem {
    margin-left: 18px;
    padding: 0 18px 0 0;
    max-width: 210px;
}

/*Wells*/
.projectsMenu__Well {
    display: flex;
    align-items: center;
    flex-direction: column;
}

.projectsMenu__WellItem {
    margin-left: 35px;
    max-width: 170px;
}

/*Cores*/
.projectsMenu__Core {
    margin-bottom: 5px;
    display: block;
    align-items: center;
    width: 67%;
}

.projectsMenu__CoreItem {
    margin-left: 35px;
}

.projectsMenu__wellBore-CoreItem {
    max-width: 100px;
}

/*стили для wellBore*/
.projectsMenu__WellBore {
    display: block;
}

.projectsMenu__WellBoreItem {
    /*width: auto;*/
    max-width: 160px;
    margin-left: 45px;
}

.projectsMenu__WellBoreCore {
    margin-left: 55px;
    margin-bottom: 5px;
}

.projectsMenu__WellBoreCoreItem {
    margin-left: 12px;
    max-width: 129px;
}

.projectsMenu__WellBoreCoreItem .sortItem p{
    width: 108px;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow-wrap: break-word;
    overflow: hidden;
    visibility: visible;
}

/*common styles*/
.sortItem {
    display: flex;
    align-items: center;
    gap: 6px;
}


.sortItem > svg {
    flex-shrink: 0;
}

.projectsMenu__txt {
    width: 160px;

    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow-wrap: break-word;
    overflow: hidden;
}

.cursorPointer {
    cursor: pointer;
    flex-shrink: 0;
}



.projectsMenu__deleteIcon {
    visibility: hidden;
    flex-shrink: 0;
    cursor: pointer;
}

/*стили для отображения иконки удаления для каждого из
элементов*/

/* .projectEntityItem:hover .projectsMenu__deleteIcon {
    visibility: visible;
} */
.projectsMenu__PadItem:hover .projectsMenu__deleteIcon {
    visibility: visible;
}
.projectsMenu__WellItem:hover .projectsMenu__deleteIcon {
    visibility: visible;
}
.projectsMenu__CoreItem:hover .projectsMenu__deleteIcon {
    visibility: visible;
}
.projectsMenu__WellBoreItem:hover .projectsMenu__deleteIcon {
    visibility: visible;
}
.projectsMenu__WellBoreCoreItem:hover .projectsMenu__deleteIcon {
    visibility: visible;
}

/*SimpleBar*/
.projectsMenu__projectsList-simpleBar {
    /*min-width: 70vw;*/
    width: 100%;
    height: calc(100vh - 100px);
}

.projectsMenu__projectsList-simpleBar .simplebar-mask {
    width: auto !important;
}

.projectsMenu__projectsList-simpleBar .simplebar-track.simplebar-vertical {
    right: 1px;
}

.delete-project-modal{
    height: 104px;
}


.projectMenu-sortIcon-opened{
    transform: rotate(90deg);
}

.projectMenu-sortIcon{
    transform: rotate(0);
}