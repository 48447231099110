.add-core-box-photo-dnd{
    margin-top: 10px;
}

.add-core-box-photo-item{
    margin-top: 12px;
    display: grid;
    grid-template-columns: 270px 1fr;
    align-items: center;
    gap: 12px;
    font-size: 18px;
}

.add-core-box-photo-item div{
    color:var(--main-text-color);
}


.add-core-box-photo-core-length-intervals{
    display: flex;
    align-items: center;
    gap: 8px;
}

.add-core-box-photo-core-length-intervals .input-wrapper{
    width: 100px;
}

.add-core-box-photo-file-attach .ant-upload-drag-container{
    font-size: 18px;
}

.add-core-box-photo-file-attach .drag-zone-highlight-text{
    font-size: 18px;
}

.add-core-box-photo-inner--loading{
    height: 582px;
    display: flex;
    align-items: center;
    justify-content: center;
}