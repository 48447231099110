.mnem-matching-top {
    display: grid;
    grid-template-columns: 3% 18% 13% 17% 17% 15% 10%;
    column-gap: 10px;
    background: rgba(217, 217, 217, 0.2);
    padding: 5px;
    border-radius: var(--border-radius) var(--border-radius) 0 0;
}

.additionalRow {
    display: flex;
    gap: 20px;
}

.current-lang {
    margin: 0 0 10px 0;
    display: flex;
    align-items: center;
    gap: 10px;

}

.current-lang div {
    cursor: pointer;
    color: var(--main-text-color);
    text-transform: uppercase;
}

.currentLang_active {
    font-weight: 600;
}

