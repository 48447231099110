.notification{
    border:1px solid var(--border-gray);
    position: relative;
}

.notification-unread{
    background-color: var(--main-phone);
    cursor: pointer;
}

.notification-sender__img{
    width: 30px;
    height: 30px;
    border-radius: 50%;
    object-fit: cover;
}

.notification-sender-name-phone{
    background: var(--mint-color);
    width: 30px;
    height: 30px;
    border-radius: 50%;

    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-size: 20px;
}

.notification-buttons .ant-btn{
    border-radius: var(--border-radius);
    font-size: 15px;
    color:var(--main-text-color);
    line-height: 15px;
}

.notification-buttons .ant-btn:nth-child(1){
    background-color: var(--light-green);
}

.notification-buttons .ant-btn:nth-child(2){
    background-color: var(--light-red);
}

.notification-top{
    padding-right: 20px;
    position: relative;
}

.notification-top-cancel-icon{
    position: absolute;
    right: 16px;
    top: 17px;
    cursor: pointer;
    z-index: 10;
}

.notification-date{
    position: absolute;
    right: 50px;
    top: 12px;
}

.notification-top-cancel-icon:hover{
    stroke: var(--error-text-color) !important;
}

.notification .top__title{
    margin-bottom: 10px !important;
}

.notification-top-project-name{
    display: flex;
}

.notification-top-project-name span:last-child{
    margin-left: 5px;
}

.notification .container{
    padding-top: 0 !important;
    background-color: transparent !important;
}

.notification-task-changes-block .projectTitle{
    display: none;
}

.notification-task-changes-block span,
.notification-task-changes-block div,
.notification-task-changes-block h2,
.notification-task-changes-block th {
    color:var(--main-text-color);
    font-size: 14px !important;
    font-weight: 400;
    text-align: left;
}

.notification-task-changes-block img{
    object-fit: cover;
}

.notification-task-changes-block .user__name{
    font-size: 15px;
    font-weight: 400;
    line-height: 27px;
}

.notification-task-changes-block .wrapper{
    margin-top: 10px;
}

.notification-task-changes-block .date{
    margin-top: 20px;
}

.notification-task-changes-block table{
    margin-bottom: 4px;
}
.notification-task-changes-block table tr{
    display: flex;
    max-width: 600px;
    flex-wrap: wrap;
}

.notification-task-changes-block table th{
    margin-top: 4px;
    display: flex;
    max-width: 600px;
    flex-wrap: wrap;
}