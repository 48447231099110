.add-parameter-zone-space {
  margin-top: 2px;
  min-height: 142px;
  border-radius: var(--border-radius);
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
  border: 1px dashed;

  & > div {
    height: 100%;
    min-height: 133px;
    padding: 10px;
    margin: 5px;
    border-radius: var(--border-radius)
  }
}

.add-parameter-zone-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.add-parameter-zone--add-icon--disabled {
  cursor: not-allowed;
  opacity: .6;
}

.add-parameter-zone-description {
  margin-top: -5px;
}

.add-parameter-zone-space__orange {
  border-color: #FF9F2D;

  & > div {
    background-color: rgba(255, 159, 45, 0.20);
  }
}

.add-parameter-zone-space__gray {
  border-color: #8194A6;

  & > div {
    background-color: var(--light-gray);
  }
}

.add-parameter-zone-space__green {
  border-color: #8194A6;

  & > div {
    background-color: var(--light-green2);
  }
}

.custom-dropdown-block .custom-dropdown-tags-select {
  & > div:nth-child(2) {
    width: 78% !important;
  }

  & div, & span {
    font-size: 12px !important;
  }

  .ant-select-item-option-content {
    font-size: 12px !important;
  }

  .ant-select-item, .ant-select-item-empty {
    font-size: 12px !important;
  }
}

.numeric-value-block-top {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.numeric-value-block-top .input-wrapper .ant-input {
  height: 19px !important;
  background: #E7EFF3;
  border: 1px solid var(--green-success);
}

.numeric-value-block-top .select-input-block--optionText {
  font-size: 10px;
}

.numeric-value-block-top input {
  font-size: 10px;
}

.numeric-value-block-value-range {
  margin-top: 5px;
  display: flex;
  flex-direction: column;
  gap: 3px;

  & .value-range-block {
    margin-top: -5px;
  }
}

.numeric-value-block-value-radio-group .fields-interval {
  max-width: 80px;
}

//set-pairs-block
.set-pairs-block-top {
  display: flex;
  align-items: center;
  gap: 5px;
  flex-direction: column;
}

.set-pairs-block-top .input-wrapper .ant-input {
  height: 19px !important;
  background: #E7EFF3;
  border: 1px solid var(--green-success);
}

.set-pairs-block-top .select-input-block--optionText {
  font-size: 10px;
}

.set-pairs-block-top input {
  font-size: 10px;
}

.set-pairs-block-points {
  & > div {
    .select-input-block .select-input-block--input {
      font-size: 12px !important;
    }
  }

  & .select-input-block--optionText {
    font-size: 10px;
  }

  .set-pairs-value-number-block {
    margin-top: 10px;
    display: flex;
    align-items: center;
    gap: 5px;

    & .input-wrapper, & .ant-input {
      height: 12px !important;
      width: 22px !important;
      font-size: 8px !important;
      padding: 0 4px !important;
      box-sizing: border-box;
    }
  }


}

.set-pairs-value-range-columns {
  display: flex;
  align-items: center;

  & .fields-interval .input-wrapper {
    width: 24px;
  }
}

.set-pairs-block-points {
  span.ant-radio + * {
    padding-left: 4px !important;
  }
}

.set-pairs-value-max-number-field--filled {
  background: #E7EFF3 !important;
  border: 1px solid var(--green-success) !important;
}

.add-parameter-zone-form-wrapper{
  width: 70px !important;
  height: 40px !important;
  max-height: 40px !important;
  overflow: hidden;
  margin: 0;
  display: inline-flex;
}

.add-parameter-zone-form-wrapper--isDragging {
  width: 70px !important;
  height: 40px !important;
  max-height: 40px !important;
  overflow: hidden;
  margin: 0;
  border-radius: var(--border-radius);

  & .add-parameter-zone-space > div > div{
    display: none;
  }

  & .add-parameter-zone-space > div{
    margin: 0;
    border-radius: var(--border-radius);
    overflow: hidden;
  }
}

.add-parameter-zone-form-item--isDragging{
  width: 70px !important;
  max-height: 40px !important;
  overflow: hidden;
  margin: 0;
  border-radius: var(--border-radius);
}

.add-parameter-zone-form-item--disabled > div > div{
  cursor: not-allowed;
}

.add-parameter-zone-form-item--dragging-zone{
  min-height: 40px;
}


.add-parameter-zone-draggable-parameter{
  width: 70px;
  height: 40px;
  border:1px dashed;
  border-radius: var(--border-radius);
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: wrap;
  text-align: center;
}

//common styles


