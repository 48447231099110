.roadmap-items-inner{
    max-height: calc(100vh - 318px);
}

.roadmap-items{
    max-width: 740px;
   

    display: flex;
    flex-direction: column;
    gap: 5px;
    /* overflow-y: auto; */
    /* overflow: hidden; */
}

