.exel-viewer-wrapper{
    margin: 0 auto 0;
    max-width: 1300px;
    /* max-height: 850px;
    overflow-y: scroll; */
}

.exel-viewer-wrapper-top{
    margin-bottom: 10px;
    display: flex;
    gap: 10px;
}

.exel-viewer {
    height: 100%;
    max-height: 850px;
    padding-bottom: 5px;

    border-radius: var(--border-radius);
    overflow: auto;
}

.exel-viewer::-webkit-scrollbar {
    width: 9px;
    height: 9px;
  }
  .exel-viewer::-webkit-scrollbar-track {
    background: transparent;
  }
  .exel-viewer::-webkit-scrollbar-thumb {
    height: 1px !important;
    width: 1px !important;

    border-radius: var(--border-radius);
    border: transparent;
  }

.exel-viewer .table-responsive table {
    width: 100%;
    background-color: var(--main-phone);
    border-radius: var(--border-radius);
}

.exel-viewer .table thead {
    background-color: var(--light-blue-success);
}


.exel-viewer .table-responsive .table td,
.exel-viewer .table-responsive .table th {
    padding: 7px;
    font-size: 14px;
    color: var(--main-text-color)
}

.exel-viewer-input {
    width: auto !important;
    /* border:1px solid var(--main-text-color) !important; */
    border: 1px solid transparent !important;
    border-radius: var(--border-radius) !important;
    background-color: transparent !important;
}

.exel-viewer .table thead  tr{
    text-align: left;
}

.exel-viewer-input:focus {
    border: 1px solid var(--main-text-color) !important;
}

.exel-viewer-top {
    margin-bottom: 10px
}

.exel-viewer .input-wrapper{
    text-align: left;
}

.exel-viewer-question-block{
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 10px;
}

.exel-viewer-question-block > div{
    font-size: 18px;
    color:var(--main-text-color);
}

.exel-viewer-question-buttons{
    display: flex;
    align-items: center;
    gap: 10px;
}

.exel-viewer-wrapper-top .ant-btn{
    border-radius: var(--border-radius);
}