.recoveryMessage__textBlock {
    margin-bottom: 20px;

    font-weight: 400;
    font-size: 24px;
    line-height: 35px;
    color: #000;
    text-align: center;
}

.recoveryMessage__linkWrap {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 10px;
}

.recoveryMessage__textBlock{
    color:var(--white-color)
}
