.progressBlock {
    width: 100px;
    height: 22px;

    display: flex;
    align-items: center;
    justify-content: center;

    background: #E4D1D1;
    border-radius: var(--border-radius);

    position: relative;
}

.progressBlock__percent {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    background: rgba(159, 135, 135, 0.6);
    border-radius: var(--border-radius);
}

.progressBlock span{
    color:var(--main-text-color);
    position: relative;
    z-index: 1;
    font-size: 14px;
}