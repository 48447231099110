.statuses__task {
    width: 100%;
    height: 100px;
    padding: 8px 0 8px 10px;
    background: #ffffff;
    border-radius: var(--border-radius);
    margin-bottom: 10px;
    position: relative;
}

.statuses__task:last-child {
    margin-bottom: 0;
}

.statuses__task-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.statuses__task-column {
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 14px;
}

.priorityStatus {
    width: 20px;
    height: 20px;
    border-radius: var(--border-radius);
}

.statuses__task-popover .ant-popover-content {
    transform: translate(23px, -12px);
}
