.settings {
    width: 100%;
    height: calc(100vh - 45px);
    display: grid;
    grid-template-columns: var(--menu-width) 1fr;
    column-gap: 10px;
}

.settings ul {
    padding: 10px;
    margin-bottom: 0;
    background-color: var(--gray-border-color);
}

.settings__route {
    padding: 10px 0 10px 0;
    height: 100%;
    overflow: hidden;
    overflow-y: auto;
}

.settings-btn-list {
    display: flex;
    flex-direction: column;
    gap: 5px;
}
