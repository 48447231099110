.mnem-table-item {
    margin: 7px 0;
    display: grid;
    grid-template-columns: 3% 18% 13% 17% 17% 15% 10%;
    align-items: center;
    column-gap: 10px;
    color: var(--main-text-color);
    position: relative;
}

.mnem-table-item .ant-select{
    background-color: transparent !important;
}

.mnem-table-buttons {
    display: flex;
    align-items: center;
    justify-content: center;
}

.mnem-table-radioBtn {
    width: 22px;
    height: 22px;
    flex-shrink: 0;
    cursor: pointer;
    transform: translateX(-8px);
}

.mnem-table-cancelBtn {
    position: absolute;
    right: -4%;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    align-items: center;
    justify-content: center;
}

.select-input-block--mnemonic {
    width: 100%;
    height: 100%;
    padding: 0 5px 0 5px;
    background: #e7eff3 !important;
    border: 0.5px solid var(--green-success) !important;
    outline: none;
    color: var(--main-text-color);
    font-size: 14px;
}

.select-input-block {
    height: 100%;
    display: flex;
    background: #e7eff3;
    border: 0.5px solid var(--green-success);
    border-radius: var(--border-radius);
}
