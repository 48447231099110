.langPanel {
    position: relative;
    z-index: 1;
    transition: all 0.3s ease;
}

.langPanel__top {
    width: 60px;
    height: 35px;

    display: flex;
    align-items: center;
    justify-content: center;
    gap: 3px;

    background: #e9eff3;
    border-radius: var(--border-radius);
    cursor: pointer;
}
.langPanel__top button {
    border-style: none;
    background: transparent;
    cursor: pointer;
    font-size: 18px;
    color: var(--second-text-color);
}

.langPanel__bottom {
    position: absolute;
    top: 40px;
    left: 0;

    overflow: hidden;
    transition: all 0.3s ease;
    cursor: pointer;
}

.langPanel__select {
    /*height: 50px;*/

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    background: #fff;
    border-radius: var(--border-radius);
    font-size: 17px;
    transition: all 0.3s ease;
}
.langPanel__select-option {
    width: 100%;
    padding: 3px 10px;
    margin-top: 3px;

    position: relative;
    z-index: -1;

    transition: all 0.3s ease;
    color: var(--second-text-color);
    border-radius: var(--border-radius);
}

.langPanel__select-option:hover {
    background: #dedada;
}
