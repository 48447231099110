p {
    margin: 0;
}

.userAvatar {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.userAvatar__txt {
    width: 35px;
    height: 35px;
    border-radius: 50%;

    display: flex;
    align-items: center;
    justify-content: center;

    margin: 0;
    padding: 0;
    font-family: inherit;
    font-size: 25px;
    color: #fff;
}

.userAvatar__img {
    width: 100%;
    height: 100%;

    object-fit: cover;
}
