.UserProfile {
    width: 100%;
    display: flex;
    flex-direction: row;
    overflow-x: hidden;
}

.userProfile-spinner {
    height: 100vh;

    display: flex;
    align-items: center;
    justify-content: center;
}



.top_menu {
    width: 100%;
    height: 45px;
    padding: 0 10px;

    display: flex;
    align-items: center;
    justify-content: space-between;

    background-color: #ffffff;
    position: relative;
}

.top_menu-leftSide {
    display: flex;
    gap: 10px;
    align-items: center;

    position: relative;
    z-index: 10000000;
}

.top_menu-buttons {
    padding: 0;
    margin: 0;

    display: flex;
    gap: 3px;

    border: 1px solid var(--main-text-color);
    border-radius: var(--border-radius);
}

.top_menu-buttons button {
    padding: 3px;
    border-style: none;
    background: transparent;
    cursor: pointer;
    color: var(--main-text-color);
    font-size: 14px;
}

.header_user_box {
    width: 50%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    transform: translateY(10%);
}

.header_user_box_head {
    font-style: normal;
    font-weight: 400;
    font-size: 25px;
    line-height: 35px;
    color: var(--second-text-color);
    margin-left: 3%;
}

.user_avatar {
    width: 100%;
    height: 100%;
}

.user_name_box_letter {
    margin: 0;
    font-style: normal;
    font-weight: 400;
    font-size: 25px;
    line-height: 30px;
    color: #ffffff;
}

.fa-angle-down {
    display: none;
}

.icon1_box,
.icon2_box,
.icon3_box,
.icon4_box {
    border-radius: var(--border-radius);
    width: 80%;
    height: 45px;
    margin-top: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--main-text-color);
}

.icon2_box,
.icon3_box,
.icon4_box {
    margin-top: 10px;
}
.icon3_box {
    width: 75%;
}

.user_main_box {
    margin-left: 50px;
    width: calc(100% - 50px);
    min-height: 100vh;
    height: 100%;
    display: flex;
    flex-direction: column;
    background: #e9eff3;
}

.user_bottom_box {
    width: 100%;
    height: 700px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}

.user_bottom_menu_box {
    width: 190px;
    height: 50%;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: 15px;
}



.change_password_btn {
    margin-top: 7px;
}



.user_bottom_data_password_box {
    height: 50%;
    display: flex;
}



.alert_div,
.alert_change_div {
    width: 30%;
    height: 20%;
    position: absolute;
    top: 45px;
    left: 35%;
    background: #efffee;
    border-radius: var(--border-radius);
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    justify-content: flex-start;
}

.alert_change_div {
    height: 12%;
}

.alert_exit_box {
    width: 100%;
    height: 25%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.alert_exit {
    width: 7%;
    height: 80%;
    font-size: 30px;
    font-weight: 100;
    color: var(--second-text-color);
    background: none;
    border: none;
    transform: rotate(45deg);
    cursor: pointer;
}

.alert_text {
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 23px;
    text-align: center;
    color: var(--second-text-color);
}

.alert_text > span {
    border-bottom: 1px solid;
    cursor: pointer;
}

.user_bottom_data_form {
    width: 65%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.user_data_box {
    width: 90%;
    height: 18%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 15px;
}

.user_data_box > .user_profile_label {
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 22px;
    color: var(--second-text-color);
}

.user_data_box > .user_profile_label > input {
    background: #e9eff3;
    font-style: normal;

    font-weight: 400;
    line-height: 30px;
    text-indent: 6%;
    border-radius: var(--border-radius);
    border: none;
}

.user_profile_label > p {
    margin-left: 5%;
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 22px;
    color: var(--second-text-color);
}

.user_bottom_comment_box > .user_profile_label > p {
    margin-left: 3%;
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 22px;
    color: var(--second-text-color);
}

.user_bottom_data_head_box {
    align-items: center;
    justify-content: flex-start;
    height: 20%;
}

.user_bottom_data_head {
    font-weight: 400;
    font-size: 24px;
    line-height: 29px;
    margin-left: 4%;
    color: var(--second-text-color);
}

.user_bottom_comment_box {
    width: 90%;
    height: 25%;

    display: flex;
}

.user_bottom_comment_box > .user_profile_label > textarea {
    height: 80%;
    background: #e9eff3;
    border-radius: var(--border-radius);
    border: none;
    resize: none;
    font-size: 15px;
    line-height: 18px;
    padding-left: 3%;
    padding-top: 1%;
}

.user_bottom_comment_box > .user_profile_label > textarea::placeholder {
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    color: var(--placeolder-text-color);
}

.user_bottom_comment_box > .user_profile_label {
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    font-style: normal;

    font-weight: 400;
    font-size: 15px;
    line-height: 22px;
    color: var(--second-text-color);
}

.user_bottom_comment_box > .user_profile_label > textarea:focus {
    outline: none;

    font-size: 15px;
    line-height: 17px;
}

.user_bottom_comment_box > .user_profile_label > textarea:focus::placeholder {
    opacity: 0;
    transition: 0.3s;
}

.user_bottom_save_box {
    width: 90%;
    height: 15%;
    display: flex;
    align-items: flex-end;
}

.btn_save {
    width: 100%;
    height: 36px;
    background: var(--yellow);
    border: none;
    border-radius: var(--border-radius);
    cursor: pointer;
}

.user_bottom_data_top_box {
    width: 75%;
    height: 30%;

    display: flex;
    flex-direction: column;
    align-items: flex-start;

    background: #ffffff;
    justify-content: space-around;
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;
}

.user_image_box {
    width: 80px;
    height: 80px;
    margin-left: 8%;

    display: flex;
    justify-content: center;
    align-items: center;

    border-radius: 50%;
    overflow: hidden;
    cursor: pointer;
    position: relative;
}

.user_icon_box {
    width: 10%;
    margin-left: 8%;
    height: 15%;

    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.edit_icon_box,
.remove_icon_box {
    width: 20px;
    height: 20px;

    position: relative;

    cursor: pointer;
}

.file_input {
    width: 20px;

    height: 20px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;

    cursor: pointer;
    opacity: 0;
}

.edit_img,
.delete_img {
    width: 20px;
    height: 20px;

    cursor: pointer;
}

.edit_img {
    position: absolute;
    z-index: 1;
}


.change_password_box {
    max-width: 700px;
    padding: 20px;
    background: var(--white-color);
}

.change_password_header_box {
    justify-content: flex-start;
    align-items: center;
}

.change_password_reset_box {
    justify-content: flex-start;
    align-items: center;
    margin-top: 0.5%;
}

.first_last_email_box {
    width: 80%;
    height: 80%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.first_last_box,
.email_box {
    width: 100%;
    height: 40%;
    display: flex;
    flex-direction: row;

    align-items: flex-start;
    overflow: hidden;
    margin-left: 2%;
}

.name_first_letter {
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 29px;
    color: #ffffff;
}

.first_name_text,
.last_name_text,
.email_text {
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 17px;
    color: var(--second-text-color);
}

.last_name_text {
    margin-left: 4%;
}

.name_surname_box,
.setting_logout_box {
    /*width: 95%;*/
    height: 50%;
    display: flex;
}

.name_surname_box {
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
}

.setting_logout_box {
    flex-direction: column;
}

.setting_box,
.logout_box {
    width: 90%;
    height: 50%;

    display: flex;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;
}

.user_profile_data_icon1 {
    width: 28px;
    height: 28px;
    margin-left: 4%;
}

.user_profile_data_icon2 {
    width: 28px;
    height: 28px;
    margin-left: 4%;
}

.setting_text,
.logout_text {
    font-size: 14px;
    line-height: 17px;
    color: var(--second-text-color);
    margin-left: 6%;
}

.logout_box > a {
    text-decoration: none;
}

@media screen and (max-width: 2000px) {
    .top_menu-leftSide {
        /*margin-right: 50px;*/
    }
}

@media screen and (max-width: 1350px) and (min-width: 1201px) {
    .user_profile_btn,
    .change_password_btn {
        width: 130px;
    }
}

@media screen and (max-width: 1200px) and (min-width: 800px) {
    .left_menu {
        /*width: 6%;*/
    }

    .user_bottom_menu_box {
        width: 20%;
    }

    .user_profile_btn,
    .change_password_btn {
        width: 130px;
    }

    .user_bottom_data_form {
        width: 80%;
    }

    .user_bottom_data_top_box
     {
        width: 90%;
    }

    .user_personal_data_box {
        /*width: 50%;*/
    }

    .name_first_letter_box {
        width: 25px;
        height: 25px;
    }

    .first_last_box,
    .email_box {
        height: 30%;
    }

    .user_icon_box {
        margin-left: 9%;
    }

    .alert_div,
    .alert_change_div {
        width: 50%;
        height: 10%;
    }
}

@media screen and (max-width: 799px) and (min-width: 576px) {
    /*.left_menu {*/
    /*    width: 8%;*/
    /*}*/

    .user_bottom_menu_box {
        width: 30%;
    }

    .user_profile_btn,
    .change_password_btn {
        width: 120px;
    }

    .user_bottom_data_form {
        width: 90%;
    }

    .user_bottom_data_top_box,
    .user_bottom_data_bottom_box {
        width: 100%;
    }

    .user_image_box {
        width: 110px;
        height: 110px;
    }

    .user_personal_data_box {
        width: 90%;
        height: 40%;
    }

    .name_first_letter_box {
        width: 25px;
        height: 25px;
    }

    .first_last_box,
    .email_box {
        height: 30%;
    }

    .user_icon_box {
        margin-left: 13%;
        justify-content: space-between;
        width: 15%;
    }

    .user_bottom_data_password_box {
        width: 90%;
    }

    .eye_open_image {
        margin-bottom: 30%;
    }

    .alert_div,
    .alert_change_div {
        width: 70%;
        height: 10%;
        left: 20%;
    }
}

@media screen and (max-width: 576px) {
    .UserProfile {
        width: 100%;
        min-height: 100vh;
        height: 1000px;
    }

    .user_main_box {
        height: 100%;
    }





    .user_bottom_data_form {
        width: 100%;
    }

    .user_bottom_data_top_box,
    .user_bottom_data_bottom_box {
        width: 100%;
    }

    /*.left_menu {*/
    /*    width: 15%;*/
    /*}*/

    .icon2_box,
    .icon3_box {
        margin-top: 5%;
    }

    .user_bottom_data_head_box {
        justify-content: center;
        align-items: center;
    }

    .user_bottom_data_head {
        font-size: 20px;
        font-weight: 500;
        line-height: 24px;
        margin: 0;
    }

    .user_icon_box {
        width: 20%;
        margin-left: 0;
    }

    .user_bottom_menu_box {
        width: 100%;
    }

    .user_profile_btn,
    .change_password_btn {
        width: 150px;
        margin: 0;
    }

    .user_bottom_data_box {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        border-radius: var(--border-radius);
    }

    .user_bottom_data_form {
        width: 100%;
        height: 100%;
    }

    .user_bottom_data_top_box {
        width: 100%;
        height: 20%;
        align-items: center;
        justify-content: space-between;
    }

    .user_image_box {
        width: 80px;
        height: 80px;
        margin: 0;
    }

    .user_data_box {
        height: 20%;
        flex-direction: column;
        justify-content: space-between;
    }

    .user_bottom_data_head_box {
        justify-content: center;
        align-items: flex-start;
    }

    .user_data_box > .user_profile_label {
        width: 100%;
        height: 50%;
    }

    .user_data_box > .user_profile_label > input {
        width: 100%;
    }

    .user_bottom_comment_box {
        width: 90%;
        height: 20%;
    }

    .user_bottom_comment_box > .user_profile_label > textarea {
        width: 100%;
    }

    .user_bottom_comment_box > .user_profile_label > textarea::placeholder {
        opacity: 0;
    }

    .user_bottom_save_box {
        align-items: center;
    }

    .btn_save {
        width: 100%;
        height: 50%;
    }

    .user_bottom_data_form {
        width: 100%;
        justify-content: flex-start;
    }

    .user_bottom_data_bottom_box {
        height: 80%;
    }

    .user_bottom_data_password_box {
        width: 100%;
    }


    .change_password_box {
        justify-content: flex-start;
    }

    .change_password_header_box {
        justify-content: flex-start;
        align-items: flex-start;
        height: 30%;
    }

    .change_password_input_box {
        flex-direction: column;
        justify-content: space-evenly;
        height: 50%;
    }

    .user_name_box {
        margin-right: 8%;
    }

    .fa-angle-down {
        position: absolute;
        top: 5px;
        right: 1%;
        cursor: pointer;
        display: inline-block;
        font-size: 24px;
    }

    .activeWindow {
        display: flex;
        width: 150%;
        height: 50%;
        position: absolute;
        top: 42px;
        right: -2%;
    }

    .alert_div,
    .alert_change_div {
        width: 85%;
        height: 10%;
        left: 14%;
    }

    .alert_div {
        height: 20%;
    }

    .alert_text {
        font-size: 14px;
    }
}
