.topItem{
  display: flex;
  flex-direction: column;

  &__top{
    display: flex;
    align-items: center;
    gap: 5px;

    svg{
      width: 21px;
      height: 21px;
    }
  }

  & div{
    color:var(--main-text-color);
    font-size: 14px;
  }

  & span{
    font-size: 14px;
  }

}