


.roadmap-calendar-groups {
    width: fit-content;
    display: flex;

    background-color: var(--white-color);
    position: relative;
}

.roadmap-calendar-groups::before{
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 1px;
    height: 100%;
    background-color: var(--field-border);
}

.roadmap-calendar-group-items {
    display: flex;
    height: 100%;
}

.roadmap-calendar-group{
    position: relative;
    background-color: var(--white-color);
}

.roadmap-calendar-group:after{
    content: '';
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    width: 1px;
    height: 100%;
    background-color: var(--field-border);
}

.roadmap-calendar-item {
    width: 50px;
    display: flex;
    justify-content: center;

    position: relative;
    transition: all .2s ease;
    background-color: var(--white-color);
}

.roadmap-calendar-hovering-item:hover{
    /* border:1px solid var(--main-text-color) */
    background-color: rgb(222, 229, 251);
    transition: all .2s ease;
    cursor: pointer;
}

.roadmap-calendar-group-label {
    text-align: center;
    background-color: var(--grey-phone-color);
}

.roadmap-calendar-item-nowDate-line{
    width: 1px;
    position: absolute;
    height: 33px;
    top: 0;
    /* top: -119%; */
    bottom: 0;
    background-color: #FF9F2D;
    z-index: 1;
}

.roadmap-calendar-item-nowDate-line-header{
    width: 7px;
    height: 7px;

    position: absolute;
    bottom: 0;
    z-index: 1;

    border-radius: 50%;
    background-color: #FF9F2D;

    transform: translateX(-46%)
}


.roadmap-calendar-item-nowday{
    width: 1px;
    height: 120%;
    position: absolute;
    z-index: 1;
    background-color: var(--orange);
}

.roadmap-calendar-item-nowday-first::before{
    content:'';

    width: 5px;
    height: 5px;
    border-radius: 50%;

    position: absolute;
    top: 0;
    right: 0;

    transform: translateX(41%);

    background-color: var(--orange);
}