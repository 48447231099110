.coreStudies {
    min-height: 350px;

    display: grid;
    grid-template-columns: 285px 1fr;
}

.coreStudies-spin {
    display: flex;
    align-items: center;
    justify-content: center;
    height: calc(100vh - 480px);
}

.coreStudies-custom-group-spin{
    position: fixed;
    height: 100%;
}

.coreStudies__menu {
    padding-right: 4px;

    display: flex;
    gap: 8px;

    border-right: 1px solid rgba(0, 0, 0, 0.2);
}

.coreStudies__menuIcons {
    padding-right: 10px;

    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
}

.coreStudies__menuIcons img {
    width: 40px;
    height: 40px;
    object-fit: contain;
}

.coreStudies__menuIcons img:last-child {
    transform: translateX(-6px);
}

.coreStudies__menuItems {
    display: flex;
    flex-direction: column;
    gap: 8px;
}
.coreStudies__menuText {
    font-size: 20px;
    line-height: 26px;
    word-break: break-word;

    color: var(--main-text-color);
    cursor: pointer;
}

.coreStudies__workSpace {
    padding-left: 11px;
}

/*Common setting items styles*/

.coreStudySetting__container {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.coreStudySetting__plusBtn {
    cursor: pointer;
    justify-self: right;
}

.coreStudySetting__item {
    display: grid;
    gap: 10px;
}

.active-measurement{
    text-decoration: underline;
}
