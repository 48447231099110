.matchSettings_tab{
    padding: 2px 9px;
}

.matchSettings_tab_active{
    background: rgba(217, 217, 217, 0.62);
    border-radius: var(--border-radius);
    transition: all .2s ease-in-out;
}



