.projects__workSpace {
    padding: 9px 21px 0 15px;

    color: var(--second-text-color);
    transition: all .3s ease-in-out;
}

.projects__workSpace-simpleBar {
    height: 100%;
    padding-bottom: 12px;
}

.projects__workSpace-simpleBar .simplebar-mask {
    width: auto !important;
}

.projects__workSpace-simpleBar .simplebar-track.simplebar-vertical {
    right: -1px;
}

.projects__workSpace-spin {
    /*width: calc(100% - 230px);*/
    width: 100vw;
    height: 85vh;

    display: flex;
    align-items: center;
    justify-content: center;
}

.projects__info {
    max-width: 980px;
    margin-top: 10px;

    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: 1fr 1fr;
    row-gap: 10px;
}

/*Projects elements*/
.projectAccordion__topChildren {
    display: grid;
    grid-template-columns: 163px 205px 180px 130px 140px;
    gap: 10px;
}

.projects__elements-menu {
    display: grid;
    grid-template-columns: 170px 185px 1fr;
    column-gap: 35px;
}

/*Tabs*/

.projects__tabs {
    margin: 10px 0 0 0;
}

.projects__tabCore-inner {
    display: grid;
    grid-template-columns: 175px 1fr;
}

.no-project-block{
    font-size: 33px;
    display: flex;
    /* align-items: center; */
    justify-content: center;
    margin-top: 27%;
    color: var(--main-text-color);
}

/*Common styles*/

/*Pads & Cores && Wells*/
.projects__elements-list {
    background: #fff;
}

.projects__elements__inputWrap {
    padding: 5px 3px 5px;
}

/*инпут для добавления */
.projects__elements-addInput {
    width: 100%;
    height: 28px;
    padding: 0 3px 0 14px;

    background: #ffffff;
    border: 0.4px solid #979797;
    border-radius: var(--border-radius);
    outline: none;
    font-size: 18px;
}

.editClassName {
    padding: 5px 30px 5px 3px;
}

.projects__elements-simpleBar {
    /*background: #fff;*/

    max-height: calc(100vh - 350px);
    border-radius: var(--border-radius);
}

.projects__elements-plusIcon {
    /*width: 20px;*/
    /*height: 20px;*/

    cursor: pointer;
    flex-shrink: 0;
}

.projects__elements-editSpan {
    margin: 0;

    line-height: 22px;
    word-break: break-word;
}

.activeElement {
    font-weight: 600;
    background: var(--light-blue-success);

    cursor: pointer;
}

.projects__elements-editSpan .editSpanIcon {
    right: -23px;
    top: 43%;
}

.projects__elements-editSpan:hover .editSpanIcon {
    visibility: visible;
}

/*Общие стили для редактируемого span, */
.projects__elements-editInput {
    width: 100%;
    height: 30px;
    padding-left: 12px;

    border-radius: var(--border-radius);
    color: var(--main-text-color);
    border-color: var(--main-text-color);
    /*line-height: 0;*/
    font-size: 16px;
}

.projects__elements-list-simpleBar .simplebar-track.simplebar-horizontal {
    bottom: -3px;
}

.projects__workSpace-members {
    margin: 0 !important;
}

.data-logging-container{
    width: 820px;
    height: fit-content;
    margin-top: 14px;
    padding: 11px 16px;
    background: #fff;
    border-radius: var(--border-radius);
}

@media screen and (max-width: 700px) {
    .projects__info {
        gap: 10px;
    }
}

@media screen and (max-width: 900px) {
    .projects__info {
        grid-template-columns: 1fr;
    }
}
