.navigation{
    padding-bottom: 40px;
    width: 50px;
    min-height: 100%;
    background-color: var(--main-text-color);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    left: 0;
    top: 0;
    /*z-index: 100;*/
}
.navigation-top {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    flex-shrink: 0;
}