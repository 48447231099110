

.add-core-box-photo-radio-content{
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 18px;
    color:var(--main-text-color);
}

.add-core-box-photo-right-arrow{
    transform: rotate(90deg);
}
.add-core-box-photo-left-arrow{
    transform: rotate(-90deg);
}

.add-core-box-photo-btn-wrapper{
    margin-top: 12px;
    width: 100%;
}

.add-core-box-photo-btn-wrapper .ant-btn{
    width: 100%;
    height: 100%;
    font-size: 18px;
}

