
.projectsMenu__item{
    height: 35px ;
    overflow: hidden;

    display: flex;
    justify-content: space-between;
    flex-direction: column;
    font-weight: 400;
}

.projectsMenu__item-absent{
    height: 0;
    overflow: hidden;
}

.projectsMenu__item-collapsed{
    height:auto;
    overflow: visible;
}

.projectsMenu__item-text{
    font-weight: 400;

    word-break: break-all;
    max-width: 155px;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow-wrap: break-word;
    overflow: hidden;
}

.projectsMenu__item-project-selected .projectsMenu__item-text{
    font-weight: 600;
}

.projectsMenu__item-nested{
    height: 100%;
}

.projectsMenu__item-nested .projectsMenu__item-text{
    font-weight: 400;
}

.projectsMenu__item-nested > div{
    padding-left:18px;
}

.projectsMenu__item-inner{
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.projectsMenu__item-inner svg{
    flex-shrink: 0;
}

.projectsMenu__item-inner > div{
    display: flex;
    align-items: center;
    gap: 5px;
}

.projectsMenu__item-inner:hover .projectsMenu__deleteIcon{
    visibility: visible;
}

.projectsMenu__item-wellCore{
    height: 100%;
}

.projectsMenu__item-wellCore .projectsMenu__item-core{
    height: 35px;
    align-items: center;
}