* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: Montserrat, sans-serif;
}

ul,ol {
    margin: 0;
    margin-bottom: 0;
    padding: 0;

    list-style: none;
}

p,
h1,
h2,
h3,
h4,
h5,
h6,
ul {
    margin: 0;
}

button {
    border-style: none;
    outline: none;
    background: transparent;
    cursor: pointer;
}

ul {
    margin: 0;
    padding: 0;

    list-style: none;
}

html,
body {
    scrollbar-width: thin; /* толщина */
}

.ant-picker-content td,
.ant-picker-content th {
    position: relative;
    min-width: 24px;
    font-weight: 400;
    font-size: 16px;
}

.ant-picker-cell .ant-picker-cell-inner {
    font-size: 16px;
}

.ant-picker-panel-container .ant-picker-panel .ant-picker-content,
.ant-picker-panel-container .ant-picker-panel table {
    font-size: 12px;
}

.tagsDropdown-top,
.ant-input,
.ant-tag,
.ant-select-selection-item,
.ant-select-item-option-content {
    font-weight: 400;
    font-size: 14px;
    color: var(--main-text-color);
    border-radius: var(--border-radius) !important;
}

/*Form*/
.ant-col-24.ant-form-item-label > label,
.ant-col-xl-24.ant-form-item-label > label,
.ant-form-vertical .ant-form-item-label > label {
    color: var(--main-text-color);
}

.ant-form-horizontal .ant-form-item-label {
    margin-bottom: 10px;

    text-align: start;
}

.ant-form-item {
    margin-bottom: 0 !important;
}

/*Стили для табов*/
.coreInfo .ant-tabs > .ant-tabs-nav,
.ant-tabs > div > .ant-tabs-nav {
    display: flex;
}

.ant-tabs-tab {
    padding: 0;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: var(--main-text-color);
    font-size: 20px;
}

.ant-tabs-tab + .ant-tabs-tab {
    color: var(--main-text-color);
}

.ant-tabs-tab {
    font-size: 20px !important;
    color: var(--main-text-color);
    padding: 0 0 5px 0 !important;
}

.ant-tabs-ink-bar{
    height: 1.5px !important;
    background-color: var(--primary-color) !important;

}

/*убираю дефолтные стили на ховер в табах */
.ant-tabs-tab:hover {
    color: var(--main-text-color);
}

.site-input-group-wrapper .site-input-split {
    background-color: #fff !important;
}

.site-input-group-wrapper .site-input-right {
    border-left-width: 0;
}

.site-input-group-wrapper .site-input-right:hover,
.site-input-group-wrapper .site-input-right:focus {
    border-left-width: 1px;
}

.site-input-group-wrapper .ant-input-rtl.site-input-right {
    border-right-width: 0;
}

.site-input-group-wrapper .ant-input-rtl.site-input-right:hover,
.site-input-group-wrapper .ant-input-rtl.site-input-right:focus {
    border-right-width: 1px;
}

/*file Upload*/
.ant-upload-list-picture-card .ant-upload-list-item,
.ant-upload-list-picture .ant-upload-list-item {
    height: 110px !important;
}

/*notify*/
.changeEmailNotify .Toastify__toast-body > div:last-child {
    text-align: center;
}

.ant-upload.ant-upload-drag {
    border-radius: var(--border-radius) !important;
}
/* ckeleton styles*/
.skeleton-input {
    border-radius: var(--border-radius);
    width: 100% !important;
}
.skeleton-input .ant-skeleton-element {
    width: 100% !important;
}

.ant-popover-inner {
    border-radius: var(--border-radius);
}

::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    background-color: white;
}

::-webkit-scrollbar-thumb {
    width: 4px;
    height: 4px;
    background-color: var(--gray-color);
    border-radius: var(--border-radius);
    box-shadow: inset 1px 1px 10px rgb(222, 229, 251);
}


.inlog-skeleton {
    border-radius: var(--border-radius);
    width: 100% !important;
}

.drag-zone-highlight-text {
    font-size: 14px;
    color: var(--primary-color);
}

.ant-tag {
    border-radius: var(--border-radius) !important;
}

.ant-upload.ant-upload-drag {
    border-radius: var(--border-radius) !important;
}

.ant-tabs-nav{
    margin-bottom: 20px !important;
}

.ant-skeleton-element, .ant-skeleton-input{
    width: 100% !important;
    border-radius: var(--border-radius) !important;
}

.ant-skeleton-input{
    border-radius: var(--border-radius) ;
}



