.unitWindow{
  padding: 0 7px;
  &__top, &__item{
    display: grid;
    grid-template-columns: 22% 24% 29% 8%;
    gap: 8px;
  }
  &__top{
    & > div{
      color:var(--main-text-color);
    }
  }
  &__input{
    padding-left: 2px;
    border: 1px solid transparent;
    outline: none;
    color:var(--main-text-color);

    &:focus{
      border: 1px solid var(--main-text-color);
      border-radius: var(--border-radius);
    }
  }

}