.block{
    width: 858px;

  .top{
    display: flex;
    gap: 28px;
    align-items: center;

    h3,button{
      font-size: 18px;
      color:var(--main-text-color);
    }

    h3{
      margin: 0;
      padding:0;
    }

    & > button{
      display: flex;
      gap: 10px;
      align-items: center;

      text-decoration: underline;
    }
  }
  .table{
    margin-top: 28px;

    & > div{
      margin-bottom: 8px;
      color:var(--main-text-color);
      &:first-child{
        margin-bottom: 14px;
      }
      &:last-child{
        margin-bottom: 0;
      }
    }
  }
}

.row{
  width: 100%;

 display: grid;
  grid-template-columns: 2% 21% 50% 13%;
  gap: 10px;



  & > div:last-child{
    text-align: center;
  }
}