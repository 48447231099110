.statuses {
    width: 100%;
    overflow-x: auto;
    background: var(--main-phone);
}

.statuses-top{
    padding: 10px 10px 0 10px;
}

.statuses__inner {
    padding: 10px 10px 0 10px;
}


.statuses__list-inner {
    display: flex;
    gap: 10px;
}

.addStatusBtn {
    margin-top: 4.9px;
    height: 31px;

    border-style: none;
    font-weight: 400;
    font-size: 20px;
    line-height: 29px;
    color: var(--main-text-color);
    background: var(--white-color);
}

.statuses-skeletons{
    padding: 10px 10px 0 10px;

    display: flex;
    gap: 10px;
}

.statuses-skeleton-item{
    width: 230px !important;
    height: 400px !important;

}

