.ant-picker-input {
  font-size: 16px;
}

/*стили для календаря antd*/
.ant-picker {
  height: 30px !important;
  width: 100% !important;
  
  border-radius: var(--border-radius) !important;
}

.ant-picker-date-panel .ant-picker-body,
.ant-picker-header-view button {
    text-transform: capitalize;
}

/* .ant-picker:hover,
.ant-picker-focused {
    border-color: transparent;
    border-right-width: 0;
} */

.ant-picker-focused {
    /* border-color: transparent; */
    box-shadow: none;
    border-right-width: 1px;
    outline: 0;
}

.ant-picker-cell .ant-picker-cell-inner {
  font-size: 16px;
}

.ant-picker-header-view {
  font-size: 16px;
}

.ant-picker-panel .ant-picker-footer {
  font-family: Montserrat, sans-serif;
  font-size: 16px;
}

.ant-picker-input>input{
  color: var(--main-text-color) !important;
  font-size: 14px !important;
  line-height: 30px !important;
}