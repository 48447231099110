.popover-menu-wrap {
    cursor: pointer;
    height: 24px;
}

.popover-menu-list {
    width: 271px;
    padding: 0px 19px;
    margin: 0;
    list-style: none;
    box-sizing: border-box;
    position: relative;
    z-index: 2;
}

.popover-menu-list li {
    padding: 12px 0;
}

.popover-menu-item {
    padding: 8px 0;

    display: grid;
    align-items: center;
    grid-template-columns: 24px 1fr;
    gap: 12px;
    font-size: 12px;
}

.popover-web-info-list-wrapper {
    position: fixed;
    width: 100%;
    height: 100svh;
    z-index: 1;
    top: 0;
    left: 0;
}

.popover-web-info-list-container {
    position: fixed;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: fit-content;
    min-width: 200px;
    width: 250px;
    max-height: 80%;
    z-index: 2;
    border-radius: var(--border-radius);
    padding: 0px 16px;
    box-shadow: 0px 4px 40px 0px #00000029;
    background-color: var(--white-color);
}

.popover-web-info-list-body {
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;

    overflow-y: hidden;
    overflow-x: hidden;

    text-align: left;
}

.popover-web-groupinfo {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    padding: 8px 0;
}

.popover-web-groupinfo .copy-text-block {
    justify-content: space-between;
}

.popover-web-info-list-title {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
