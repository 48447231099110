.roadMap-workSpace-top .roadMap-workSpace-column:last-child, .roadMap-workSpace-bottom .roadMap-workSpace-column:last-child {
    overflow-x: auto;
}

.roadMap-items-skeleton {
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 5px;
}

.roadMap-items-skeleton .ant-skeleton {
    width: 100%;
    padding-left: 20px;

    background-color: var(--grey-border-color);
}

.roadMap-workSpace-top {
    display: grid;
    grid-template-columns: 758px 1fr;
}


.roadMap-workSpace-collapsed {
    max-height: calc(100vh - 220px);
}

.roadMap-workSpace-top .roadMap-workSpace-column:first-child {
    background-color: var(--white-color);
}

.roadMap-workSpace-column:first-child .roadMap-tasks,
.roadMap-workSpace-column:first-child .roadMap-calendar-top {
    border-right: 1px solid var(--field-border);
}

.roadMap-column-calendar-items {
    height: fit-content;
    width: 100%;
    /* overflow-x: auto; */
    scrollbar-width: none;
    position: relative;

    overflow: hidden; /* Скрываем системный скроллбар */

}

.roadMap-column-calendar-items:hover .roadmap-column-tasks{
    padding-right: 0;
}

.roadmap-column-tasks{
    width: 100%;
    height: 100%;
    overflow: auto; /* Прокрутка контента */
    padding-right: 20px; /* Оставляем место для "вынесенного" скроллбара */
}

.calendar-items {
    width: 100%;
    margin: 0;

    display: flex;
    flex-direction: column;
    gap: 5px;

    overflow-x: auto;
    overflow-y: hidden;
    scrollbar-width: none;
}

.calendar-items .roadmap-calendar-group {
    min-height: 27px;
}


.roadMap-workSpace-top
    .roadMap-workSpace-column:last-child
    .roadMap-workSpace-column-top {
    height: 100%;
    padding: 0;
}
.roadMap-workSpace-top
    .roadMap-workSpace-column:last-child
    .roadmap-calendar-group-items {
    height: 26px;
}

.roadMap-workSpace-bottom .roadMap-workSpace-column {
    height: fit-content;
}

.roadMap-column-tasks {
    padding-left: 20px;
}

.roadMap-workSpace-column:last-child .roadMap-workSpace-column-top {
    padding-bottom: 8px;
    position: relative;
}

.roadMap-workSpace-column:last-child .roadMap-workSpace-column-top {
    padding-bottom: 8px;
}

.roadMap-workSpace-column:last-child
    .roadMap-workSpace-column-top
    .roadmap-calendar-item {
    background-color: var(--grey-phone-color);
}

.roadMap-top-item:first-child {
    display: flex;
    gap: 14px;
}

.roadMap-top-icons {
    padding-top: 24px;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.roadMap-calendar-top {
    height: 42px;
}

.roadMap-items-top {
    width: 100%;
    border-radius: var(--border-radius) 0 0 0;
}

.roadMap-items-top-columns {
    display: grid;
    align-items: center;
    grid-template-columns: 32px 76px 70px 229px 115px 115px 120px;
}

.roadMap-items-top div {
    width: 100%;
    height: 100%;
}

.roadMap-items-top-columns > div {
    display: flex;
    align-items: center;
    justify-content: center;

    text-align: center;
    color: var(--main-text-color);
    font-size: 13px;
    background-color: var(--grey-phone-color);
}

.roadMap-items-top-columns > div:first-child {
    background-color: var(--main-phone);
}
.roadMap-items-top-columns > div:nth-child(2) {
    border-radius: var(--border-radius) 0 0 0;
}

.roadMap-tasks .pagination_paginator__3ALJU {
    background-color: transparent;
}

.roadMap-top-item-detalization {
    max-width: 210px;
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.roadMap-pagination > div > div {
    justify-content: flex-start;
}

@media (max-width: 1500px) {
    .roadMap-top {
        flex-direction: column;
        gap: 12px;
    }

    .roadMap-top-item-detalization {
        margin-left: 39px;
        flex-direction: row;
    }
}
