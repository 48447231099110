.fields-interval{
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 5px;
}

.fields-interval .input-wrapper .ant-input{
  height: 14px !important;
  border: 1px solid var(--green-success);
  font-size: 8px !important;
  background: #E7EFF3;
  padding: 3px;
}

.fields-interval--disabled .input-wrapper .ant-input{
  border: 1px solid lightgrey;
}

.fields-interval > span{
  transform: translateY(2px);
}

.fields-interval--input-error{
  border-color: var(--error-text-color) !important;
  background-color: rgb(251, 218, 226) !important;
}