.webcam-camera-video-space {
  margin: 0 auto;
}

.webcam-camera-video-space video,
.webcam-camera-img {
  width: 100%;
  object-fit: contain;
}

.webcam-camera-video-space-hidden {
  height: 0;
  width: 0;
  opacity: 1;
}

.webcam-camera-buttons {
  margin-top: 16px;

  display: flex;
  justify-content: flex-end;
  gap: 12px;
}

.webcam-camera-buttons .ant-btn {
  width: 158px;
}

:root[data-theme='dark'] .camera-modal .ant-modal-content {
  background-color: var(--black-content) !important;
}

.webcam-camera-video-space--no-camera {
  width: 100%;
  height: 480px;
  padding: 24px;

  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 16px;
}

