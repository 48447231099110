.signForm__textPreview {
    max-height: 500px;
    width: 680px ;
}

.signForm__textPreview .simplebar-mask {
    width: auto !important;
    max-height: 500px;
}

.textPreview {
    color: var(--main-text-color);
    font-size: 16px;
}

.textPreview > div{
    color: var(--main-text-color);
    font-size: 16px;
}


.signForm .ant-checkbox-wrapper:hover .ant-checkbox:after,
.ant-checkbox:hover:after {
    border: 7px;
}

.signForm__textPreview .simplebar-track.simplebar-vertical{
    right:0px !important;
}

.textPreview {
    max-width: 665px;
}

.signForm__textPreview .simplebar-scrollbar {
    cursor: pointer !important; 
}


