
.delete-modal-title{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
}

.delete-modal-title span{
    line-height: 27px;
}

.delete-modal-wrapper p{
    text-align: center;
}

.delete-modal .ant-modal-footer{
    border-top: none !important;

    display: flex;
    justify-content: center;
}

.delete-modal-cancel-btn .delete-modal-delete-btn{
    font-size: 16px !important;
}

.delete-modal-cancel-btn{
    color:var(--main-text-color) !important
}