.coreInfo__form{
    //width: 510px;
    //width: 386px;
    width: 520px;

    .wellInfo__inputWrap {
        .ant-form-item-label > label{
            font-size: 16px;
            color:var(--main-text-color);
            max-width: 240px;
        }

        & .ant-row, .ant-row::after, .ant-row::before{
            display: grid;
            grid-template-columns: 256px 240px;
            column-gap: 10px;
        }

        & .ant-form-item-control-input, .coreInfo__inputWrap .ant-select, .coreInfo__inputWrap .ant-select:not(.ant-select-customize-input) .ant-select-selector{
            height: 30px;
            font-size: 16px !important;
            color: var(--main-text-color) !important;
        }

       & .ant-input{
            height: 30px;
            font-size: 16px !important;
            color:var(--main-text-color) !important;
        }
    }

    .sendBtn{
        width: 100%;
        border-radius: var(--border-radius);
    }



}
.wellInfo__formItem{
    & > div{
        grid-template-columns: 238px 1fr !important;
    }
}


.faceDepth{
    margin: 33px 0 31px 0;
}

.faceDepth__inputs{
    display: flex;
    flex-direction: column;
    gap: 5px;
    /*gap: 20px;*/
}

.wellLogging__currentBlock .ant-form-item-label>label:after{
    content:'' !important; 
}

.well-info-upload-block{
    margin-bottom: 30px;
}

