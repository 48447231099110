.core-info-template{
  margin: 16px 0 16px;
  display: grid;
  grid-template-columns: 180px 1fr;
}
.core-info-download-btn{
  margin-top: 0;
}

.core-info-scrollbar-form {
  margin-top: 16px;
}

.core-info-items {
  width: 100%;
  max-width: 810px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 16px;
}

.col-info-form-item {
  width: 100%;
}

.col-info-form-item .ant-form-item-label > label {
  font-size: 16px !important;
}

.col-info-textarea-comment .ant-form-item-label > label {
  font-size: 16px !important;
}

.col-info-input-wrap {
  font-size: 16px;
}


.col-info-paragraph {
  margin: 15px 0 10px;
  font-size: 16px;
  line-height: 29px;
  color: var(--main-text-color);
  text-decoration: underline;
}

.core-info-double-field-wrapper{
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.core-info-double-field-wrapper .input-wrapper .input{
  border-radius: var(--border-radius) 0 0 var(--border-radius) !important;
  font-size:14px !important;
}

.core-info-double-field-wrapper .input-wrapper .core-info-bottom-field{
  border-radius: 0 var(--border-radius) var(--border-radius) 0 !important;
}

.core-info-items .core-info-datePicker {
  width: 100%;
  height: 100%;
  border-radius: 0 var(--border-radius) var(--border-radius) 0 !important;
}

.core-info-long-label{
  white-space: wrap;
}

.core-info-items .ant-form-item-label>label:after{
  content: '' !important;
}

.core-info-top{
  width: 320px;
}

.core-info-top .upload-exel-btn{
  width: 320px;
}

/*Question modal*/
.core-form-question-modal-text{
  font-size: 16px;
  color:var(--main-text-color);
}


.core-form-question-modal-text > div > div{
  font-size: 14px;
}


.core-form-question-modal-bottom{
  margin-top: 16px;
}

.core-form-question-modal-bottom{
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.core-form-question-modal-bottom .ant-btn{
  width: 100%;
  height: auto;
  white-space: wrap;
}



