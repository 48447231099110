.scheduler__menu {
    width: var(--menu-width);
    background: var(--gray-border-color);
    padding: 10px;
    height: calc(100vh - 45px);

    box-sizing: border-box;
    transition: all 0.3s ease-in-out;
}

.scheduler__menu-collapsed {
    width: var(--menu-collapsed-width);
    padding: 10px 8px;
}

.scheduler__menu-feature {
    border-radius: var(--border-radius);
}

.scheduler__menu-feature-selected {
    background-color: var(--selected-menu-item);
    transition: all 0.2s ease-in-out;
    padding: 3px 5px;
}

.scheduler__menu-feature-collapsed {
    border-radius: var(--border-radius);
    border-color: transparent;
}

.scheduler__menu-feature-collapsed-selected {
    background-color: var(--selected-menu-item);
}

.scheduler__menu-select-wrapper {
    display: grid;
    grid-template-columns: 198px auto;
    column-gap: 10px;
}

.scheduler__menu-select-wrapper > div:last-child{
    align-self: end;
    margin-bottom: 4px;
}

.scheduler__menu-create-entety{
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
}

.scheduler__menu-create-entety > div{
    margin: 0;
    padding: 0;
}

.scheduler__menu-create-entety .new-project__form{
    width: 100%;
    max-width: 100%;

    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-auto-flow: row;
    gap: 10px;
}

@media screen and (max-width: 991px) {
    .scheduler__menu-top {
        flex-direction: column;
        align-items: flex-start;
    }

    .scheduler__menu-name {
        font-size: 18px;
    }
}
