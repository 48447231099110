.porositySetting__item {
    margin-top: 10px;

    grid-template-columns: 150px 100px repeat(3,120px) 1fr;
}

.addSettingBtn {
    height: 46px;
    width: 100%;

    border-radius: var(--border-radius);
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background: var(--gray-color);
}

.addSettingBtn:disabled {
    opacity: 0.4;
}

.deleteSettingBtn {
    width: 45px;
    height: 30px;

    border-style: none;
    background: transparent;
    cursor: pointer;

    display: flex;
    align-items: center;
    justify-content: center;

    justify-self: end;
}
