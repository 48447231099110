.projects__info-team-items{
  display: flex;
  align-items: center;
  gap: 10px;
}

.projects__info-team-items .ant-btn{
  width: 100%;
  font-size: 16px;
  color:var(--main-text-color) !important;
  height: 36px;
  border-radius: var(--border-radius);
}