.team__table-item {
    padding: 7px 0;

    display: grid;
    column-gap: 10px;
    grid-template-columns: 24% 24% 18% 14% 16%;

    font-weight: 400;
    font-size: 14px;
    line-height: 29px;
    color: var(--main-text-color);
    transition: all 0.3s ease-in-out;
}
.team__table-item:first-child {
    margin-top: 0;
}

.team__table-item:nth-child(even) {
    background: #fff;
}

.team__table-item:hover {
    background: var(--gray-border-color);
}

.team__table-top {
    border-bottom: 1px solid var(--main-text-color);
    font-size: 14px;
}

.team__table-column {
    padding-left: 10px;
    word-break: break-word;
}

.team__table-column:last-child{
    width: 180px;
    text-align: left;
}


.team__table-column .select-wrapper{
  width: 180px;
    transform: translateX(-18px);
}

.team__table-column .select-wrapper span{
    font-size: 14px;
}

.team-item-roles-select .ant-select-selection-item{
    font-size: 14px !important;
}

@media screen and (max-width: 991px) {
    .team__table-item {
        font-size: 14px;
    }
}
