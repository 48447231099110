.userPersonalWindow {
    padding: 7px;

    position: absolute;
    top: 45px;
    right: 0;

    display: flex;
    flex-direction: column;
    gap: 10px;

    background: #fff;
    box-shadow: 0 5px 15px 0 rgb(0 0 0 / 20%);
    border-radius: var(--border-radius);
}


.userPersonalWindow__name,
.userPersonalWindow__settings,
.userPersonalWindow__logout {
    display: grid;
    grid-template-columns: 43px 1fr;
    align-items: center;

    color: var(--second-text-color);
}
.userPersonalWindow__name p {
    margin: 0;
}
.userPersonalWindow__settings p,
.userPersonalWindow__settings div,
.userPersonalWindow__logout {
    height: 30px;
    cursor: pointer;
    font-size: 16px;
    
}

.userPersonalWindow__text {
    overflow: hidden;
    width: 230px;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    color: var(--second-text-color);
}

.userPersonalWindow__text div:first-child {
    font-size: 16px;
}

.userPersonalWindow__logout:hover,
.userPersonalWindow__settings:hover {
    color: var(--second-text-color);
}
