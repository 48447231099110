.createPanel {
    height: fit-content;

    transition: all 0.3s ease-in-out;
    position: relative;
    z-index: 1;
}

.createPanel__top {
    position: relative;
}

.createPanel__top-btn {
    outline: none;
    cursor: pointer;
    border-style: none;
    background: transparent;

    transition: all 0.3s ease;
}
.createPanel__addIcon {
    pointer-events: none;
    flex-shrink: 0;
}

.createPanel__top h3 {
    margin: 0;
    padding: 0;
    color: var(--main-text-color);
}
.createPanel__bottom {
    width: 210px;
    padding: 0 10px;
}
.createPanel__bottom-inner {

    display: flex;
    align-items: center;
    gap: 5px;

    transition: all 0.4s ease-in-out;
    outline: none;
}

.createPanel__bottom-inner textarea {
    width: 100%;
    padding: 6px;
    height: 48px;
    background: #ffffff;
    border: 0.4px solid #e9eff3;
    border-radius: var(--border-radius);
    outline: none;
    resize: none;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    color: var(--second-text-color);
    overflow: hidden;
}
.createPanel__bottom-inner textarea::placeholder {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: var(--placeolder-text-color);
}

.createPanel__bottom-error {
    transform: translateY(3px);
    text-align: center;
    font-weight: 400;
    font-size: 13px;
    color: red;
}

.createPanel__bottom-title {
    text-align: center;
    color: var(--second-text-color);
}

.status-new {
    width: 31px;
    height: 31px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-style: none;
    background: #e9eff3;
}

/* .createPanel__bottom .prioritySelect-wrapper .ant-select-selector{
    padding: 3px 7px 0 0 !important;
} */

.create-panel-priority-list{
    margin: 0;

    display: grid;
    grid-template-columns: 15px 15px;
    grid-auto-flow: row;
    gap: 3px;

}

.create-panel-priority{
    width: 15px;
    height: 15px;

    cursor: pointer;
    border:1px solid transparent;
    border-radius: var(--border-radius);
}

.create-panel-priority-low{
    background-color: var(--low-priority);
}
.create-panel-priority-medium{
    background-color: var(--medium-priority);
}
.create-panel-priority-important{
    background-color: var(--important-priority);
}
.create-panel-priority-critical{
    background-color: var(--critical-priority);
}

.create-panel-priority-current{
    border-color: var(--main-text-color);
}

.add-enity-icon-wrap{
    width: 20px !important;
    height: 20px !important;

    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;

    border-radius: var(--border-radius);
    border:1px solid var(--main-text-color);
}

@media screen and (max-width: 991px) {
    .createPanel__bottom {
        width: 134px;
    }
    .createPanel__bottom-inner {
        gap: 5px;
    }
}
