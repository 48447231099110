.corePhotoUpload-inner {
}

.corePhotoUpload__list {
    display: flex;
    align-items: center;
    gap: 20px;
}
.corePhotoUpload__photoContainer {
    cursor: pointer;
    position: relative;
}

.corePhotoUpload__previewIcon {
    position: absolute;
    left: 4px;
    top: 23%;
    visibility: hidden;
    fill: gray;
}

.corePhotoUpload__deleteIcon {
    position: absolute;
    right: 3px;
    top: 31%;
    fill: gray;
    visibility: hidden;
}

.corePhotoUpload__photoContainer:hover .corePhotoUpload__previewIcon,
.corePhotoUpload__photoContainer:hover .corePhotoUpload__deleteIcon {
    visibility: visible;
}

.corePhotoUpload__deleteIcon:active {
    fill: #d23333;
}

.corePhotoUpload__photo,
.corePhotoUpload__btn {
    height: 70px;
    width: 70px;
    border-radius: var(--border-radius);
}

.corePhotoUpload__btn {
    width: 35px;
    height: 35px;
    background: #fff;

    border-style: none;
    outline: none;

    display: flex;
    align-items: center;
    justify-content: center;

    cursor: pointer;
}

.corePhotoUpload__photo {
    object-fit: cover;
}

.corePhotoUpload__input {
}

/*Core photo menu*/
.corePhotoMenu__top,
.corePhotoMenu__bottom-title {
    font-size: 15px;
    line-height: 22px;
    color: var(--main-text-color);
}

.corePhotoMenu__dragBlock {
    margin-top: 5px;
}

.corePhotoMenu__dragZone {
    width: 100%;

    color: var(--main-text-color);
}

.corePhotoMenu__dnb .ant-upload-list-picture .ant-upload-list-item-name,
.ant-upload-list-text .ant-upload-list-item-name {
    transform: translateY(0);
}

.corePhotoMenu__dnb > .ant-upload-list-text,
.corePhotoMenu__dnb + .ant-upload-list {
    display: block;
}

.corePhotoMenu__dnb .ant-upload-list-picture-container,
.ant-upload-list-text-container {
    width: 100%;
}

.corePhotoMenu__dragZone span {
    color: #56b6ef;
}

.corePhotoMenu__input {
    height: 27px;

    border: 0.4px solid #c4c4c4;
    border-radius: var(--border-radius);
}

.corePhotoMenu__inputBlock button {
    height: 26px;
}

.corePhotoMenu__bottom {
    margin-top: 30px;

    display: flex;
    gap: 15px;
}

.corePhotoMenu__bottom-radioGroup .ant-radio-wrapper {
    color: var(--main-text-color);
}

.corePhotoMenu__bottom-title {
    margin-bottom: 5px;
}

.corePhotoMenu__checkbox {
    color: var(--main-text-color);
    font-size: 15px;
}

.corePhotoMenu__btnWrapper {
    text-align: center;
}

.corePhotoMenu__btnWrapper .ant-btn{
    border-radius: var(--border-radius) !important;
}
