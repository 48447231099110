.notice-block{
    width: 40px;
    height: 40px;
    border-radius: 10px;

    background: var(--white-color);
    flex-shrink: 0;
    position: relative;
}

.notice-block-tabs .simplebar-mask{
    width: 100% !important;
}

.notice-block-count{
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: var(--yellow);
    flex-shrink: 0;
    position: absolute;
    right: -5px;
    top: -8px;
    text-align: center;
}

.notice-block-count-m{
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: var(--yellow);
    flex-shrink: 0;
    position: absolute;
    right: -4px;
    top: -6px;
    text-align: center;
}

.notice-block-wrap-modal .ant-modal {
    top: calc(100% - 726px) !important;
    left: 50px !important;
    max-width: initial !important;
    margin: initial !important;
}

.notice-block-tabs-top{
    margin-bottom: 20px;
    display: flex;
    gap: 10px;
    justify-content: center;
}

.notice-block-tab{
    transition: all .2s ease-in-out;
}

.notice-block-tab-active{
    transition: all .2s ease-in-out;
    text-decoration: underline;
    color:var(--primary-color);
}

.notice-block-tabs-sb{
    height: 600px;
}

.notice-block-tabs-wrapper{
    height: 600px;
}
