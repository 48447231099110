.Projects {
    /*width: 100%;*/
    min-height: calc(100vh - 45px);
    /*display: flex;*/
    position: relative;
    /* border: 1px solid; */
    display: grid;
    grid-template-columns: 219px 1fr;

    transition: all 0.2s ease-in-out;
}


.coreInfo__inputWrap .ant-form-item-label > label {
    height: 100%;
    max-width: 170px;

    font-size: 20px;
    font-family: Montserrat, sans-serif;
    color: var(--main-text-color);
    white-space: normal;
    line-height: 26px;
}

.coreInfo__inputWrap .ant-form-item-label > label:after {
    content: none;
}

.coreInfo__inputWrap .ant-form-item-control-input,
.coreInfo__inputWrap .ant-select {
    color: var(--main-text-color);
    font-size: 18px;
    font-family: Montserrat, sans-serif;
}

.coreInfo__textAreaComment .coreInfo__inputWrap .ant-form-item-control-input,
.coreInfo__inputWrap .ant-select,
.coreInfo__inputWrap
    .ant-select:not(.ant-select-customize-input)
    .ant-select-selector {
    height: 100%;
}

.coreInfo__inputWrap .ant-row,
.ant-row:after,
.ant-row:before {
    display: grid;
    grid-template-columns: 175px 200px;
    column-gap: 10px;
}

.coreInfo__textAreaWrap .ant-row,
.ant-row:after,
.ant-row:before {
    margin-top: 10px;
}

.coreInfo__row .ant-row,
.ant-row:after,
.ant-row:before {
    grid-template-columns: 175px 1fr;
}

.coreInfo__textAreaWrap .ant-row:after,
.ant-row:before {
    align-items: start;
}

.coreInfo__inputWrap
    .ant-select
    .ant-select-in-form-item
    .ant-select-status-success
    .ant-select-focused
    .ant-select-single
    .ant-select-show-arrow
    .ant-select-open {
    border: none !important;
    width: 137px;
}

.coreInfo__inputWrap .ant-input:placeholder-shown {
    width: 100%;
    border-radius: var(--border-radius);
}

.ant-select-open,
.ant-select-status-success {
    border: none;
}

.coreInfo__inputWrap .ant-form-item-control-input,
.coreInfo__inputWrap .ant-select,
.coreInfo__inputWrap
    .ant-select:not(.ant-select-customize-input)
    .ant-select-selector {
    border-radius: var(--border-radius);
    height: 30px;
}

.coreStudyInput__input {
    height: 26px;

    background: #e9eff3;
    border-radius: var(--border-radius);
    font-size: 12px;
    color: var(--main-text-color);
    text-align: center;
    line-height: 25px;
}
