.creator-with-assignees-wrapper, .creator-with-assignees-list{
    display: flex;
}

.creator-with-assignees-wrapper{
    max-width: 100px;
    gap: 10px;
}

.creator-with-assignees-wrapper .membersSelect__bottom-item{
    width: auto;
    padding: 0;
    left: 0;
}

.creator-with-assignees-rest-indicator{
    width: 17px;
    height: 17px;

    display: flex;
    align-items: center;
    justify-content: center;

    font-size: 10px;
    border-radius: 50%;
    color: var(--white-color);
    background-color: var(--main-text-color);
}