.custom-groups-view {
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.custom-group-item {
    padding: 2px;
    width: 49px;
    height: 45px;
    border-radius: var(--border-radius);
    background-color: #fddbb2;

    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.custom-group-item--active {
    border: 1px solid var(--main-text-color);
}

.custom-group-view-loader {
    position: static;
    height: initial;
    background: transparent;
}

.custom-group-item--delete-group-btn {
    position: absolute;
    top: -6px;
    right: -10px;
    background: var(--white-color);
    width: 21px;
    height: 21px;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    border: .5px solid var(--main-text-color);
    display: none;
}

.custom-group-item:hover .custom-group-item--delete-group-btn{
    display: flex;
}