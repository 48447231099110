.logsItem{
    display: grid;
    grid-template-columns: 0.2fr 1.2fr .4fr 1.0fr .4fr 1fr;
    align-items: center;
    cursor: pointer;
}

.logsItem_radio{
    height: 15px;
}

.logsItem__quality{
    display: flex;
    align-items: center;
    gap: 10px;
}

.logsItem__buttons{
    display: grid;
    grid-template-columns: repeat(3,1fr);
    align-items: baseline;
    gap: 10px;
}

.logsItem__buttons svg{
    flex-shrink: 0;
}

.logsItem__editContainer{
    height: 38px;
}

.logsItem_span{
    font-size: 14px;
    color:var(--main-text-color);
}

.logsItem__editSpan .editSpanIcon {
    right: 0;
    top: 43%;
}

.logsItem__editSpan:hover .editSpanIcon {
    visibility: visible;
}

.logsItem__editSpan_active{
 font-weight: 600;
}

.logsItem__editSpan:hover{
    background: rgba(97, 97, 97, 0.3);
    border-radius: var(--border-radius);
}

.logsItem__editSpan {
    height: 30px;
    padding: 5px 10px 5px 5px;

    color: var(--main-text-color);
    border-color: var(--main-text-color);
    font-size: 14px;

    width: 220px;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow-wrap: break-word;
    overflow: hidden;
}

.dataQualityModal{
    width: 858px;
}