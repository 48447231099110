.scheduler__tasksSettings-inner {
    width: 100%;
    background: #ffffff;
}

.scheduler__container{
    max-width: 560px;
}

.text-editor-skeleton{
    height: 85px !important;
}