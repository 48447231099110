.roadmap-edit-task-block{
    height: 0;
    padding: 0 !important;
    
    align-self: flex-end;
}

.edit-task-modal .ant-modal-body{
    padding-top: 0 !important;
}

.roadmap-add-task-block svg {
    cursor: pointer;
}

.roadmap-add-task-modal {
    width: 760px !important;
}

.roadmap-add-task-modal-settings {
    padding-top: 20px;

}
.roadmap-add-task-modal-settings .task-name-edit-block {
    padding-top: 16px;
    max-width: 98%;
}


.roadmap-add-task-modal-name-wrapper {
    display: grid;
    column-gap: 12px;
    align-items: center;
    grid-template-columns: 28px 1fr;
}

.roadmap-add-task-modal-item-name {
    font-size: 20px;
    color: var(--main-text-color);
}

.roadmap-add-task-modal-name-wrapper .ant-input {
    width: 100%;
    border-color: #e9eff3;
    font-size: 16px !important;
    background-color: #e9eff3;
}

.roadmap-add-task-modal-items-wrapper {
    margin-top: 16px;

    display: grid;
    grid-template-columns: 1fr 251px;
    column-gap: 12px;
}

.roadmap-add-task-modal-items-wrapper > div:first-child{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 12px;
}

.roadmap-add-task-modal-priority,
.roadmap-add-task-modal-status {
    display: flex;
    column-gap: 15px;
}

.roadmap-add-task-modal-status .ant-select,
.detailsSelect {
    height: 100%;
}

.roadmap-add-task-modal-status .ant-select-selection-item {
    line-height: 31px !important;
}

.roadmap-add-task-modal-status .detailsSelect__statusSelect {
    border-color: #e9eff3 !important;
    background-color: #e9eff3 !important;
}

.roadmap-add-task-modal-period{
    margin-top: 15px;
}

.roadmap-add-task-modal-period{
    display: grid;
    gap: 50px;
    align-items: center;
    grid-template-columns: 100px 1fr;

}

.roadmap-add-task-modal-period > div{
    width: 100%;

    display: flex;
    gap: 25px;
    align-items: center;
    justify-content: flex-end;
}

.roadmap-add-task-modal-period .datePicker-from, .roadmap-add-task-modal-period .datePicker-after{
    margin-top: 0;
    font-size: 20px;
    color:var(--main-text-color);
    text-transform: lowercase;
}

.roadmap-add-task-modal-tags{
    margin-top: 15px;

    display: grid;
    gap: 50px;
    align-items: center;
    grid-template-columns: 100px 1fr;
}

.roadmap-add-task-modal-members {
    height: 100%;
    padding: 8px 16px;

    background-color: #e9eff3;
    border-radius: var(--border-radius);

    display: flex;
    flex-direction: column;
    gap: 12px;
}

.roadmap-add-task-modal-buttons{
    margin-top: 15px;
    display: flex;
    justify-content: flex-end;
    gap: 8px;
}

.roadmap-add-task-modal-buttons .ant-btn{
    color: var(--main-text-color);
    border-color: transparent;
}

.roadmap-add-task-modal-save-btn{
    background-color: #97ACF8 !important;
}

.roadMap-add-task-tabs{
    margin-top: 12px;
}

.roadMap-add-task-tabs .ant-tabs-nav div {
    font-size: 18px !important;
}

.roadmap-add-task-modal-settings .editableSpan .field-wrap{
    max-width: 90%;
}
