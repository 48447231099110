.porositySettings__container {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

    .permeabilitySettings__item {
        display: grid;
        grid-template-columns: 150px 100px 80px 150px 120px 120px;
        /*grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr .7fr;*/
        gap: 10px;
        grid-auto-flow: column;
    }

.porositySettings__plusBtn {
    cursor: pointer;
    justify-self: right;
}
