.block {
    width: 100%;
    & .ant-form-item-label > label {
        font-size: 16px !important;
    }

    .formItem {
        & > div {
            display: grid;
            grid-template-columns: 180px 1fr;

            & label {
                height: auto;
                font-size: 16px !important;
                color: var(--main-text-color);
                line-height: 18px;
            }
        }
    }
}

.ant-form-item-label > label:after {
    display: none;
}

.input,
.select {
    height: 30px;

    background: #fdfdfd;
    color: var(--main-text-color);
    font-size: 16px;
}

.input {
    font-size: 14px !important;
    color: var(--main-text-color) !important;
    & .ant-input {
        width: 100%;
        padding: 0 14px;

        border-radius: var(--border-radius);
        outline: none;
        color: var(--main-text-color) !important;
        border: 1px solid #d9d9d9 !important;
    }
}

.select {
    & .ant-select:not(.ant-select-customize-input) .ant-select-selector {
        border-radius: var(--border-radius) !important;
    }
}

.option {
    color: var(--main-text-color);
    font-size: 16px;
}

.ant-form-item {
    height: 100%;
}

.ant-form-item-label > label:after {
    display: none;
}
