.tabs-top{
    display: flex;
    gap: 16px;
    align-items: center;
}

.tabs-top-item{
    padding: 6px;
    border:1px solid var(--blue-primary);
    border-radius: 10px;
    opacity: .5;
}

.tabs-top-item-active{
    background-color: var(--blue-primary);
    opacity: 1;
}

.tabs-content{
    margin-top: 12px;
}