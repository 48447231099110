.projects__elements-pads {
    margin-top: 14px;

    display: flex;
    flex-direction: column;
    /*background: #FFFFFF;*/
    border-radius: 3px;
}

.projects__elements-list {
    margin: 0;
    padding: 0;

    border-radius: var(--border-radius);
}

.projects__elements-Padslist {
    min-width: 136px;
}

.projects__elements-pad {
    margin-bottom: 13px;

    display: flex;
    align-items: center;
    gap: 7px;

    cursor: pointer;
    font-size: 14px;
}

.projects__elements-pad:last-child {
    margin-bottom: 0;
}

.projects__elements-pads-sb {
    max-height: calc(100vh - 350px);
}

.projects__elements-pads-sb .simplebar-mask {
    width: 160px !important;
}

.projects__elements-Item {
    width: 100%;
    padding: 8px 25px 8px 14px;

    display: flex;
    align-items: center;
    gap: 7px;

    cursor: pointer;
    font-size: 18px;
    color: var(--main-text-color);
    border-radius: var(--border-radius);
}

.projects__elements-Padslist--loading{
    background-color: transparent !important;
}

.pad-item--skeleton:nth-child(1) {
    margin-top: 0;
}

.pad-item--skeleton {
    margin-top: 5px;
    border-radius: var(--border-radius);
}

.pad-item--skeleton .ant-skeleton-input {
    height: 34px !important;
}
