.projectsAccordion {
    margin-bottom: 20px;
}

.projectsAccordion:first-child {
    position: relative;
    z-index: 1;
}

.projectsAccordion:last-child {
    position: relative;
    z-index: 0;
}

.projectsAccordion:last-child {
    margin-bottom: 0;
}

.projectsAccordion .top {
    padding-bottom: 9px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-direction: row-reverse;
    gap: 10px;

    font-size: 18px;
    border-bottom: 0.3px solid #979797;
    color: var(--main-text-color);
    position: relative;
    background-color: var(--main-phone);
}

.top__arrow{
    width: 22px;
    height: 22px;
    border: 1px solid var(--main-text-color);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: var(--border-radius);
    background-color: var(--white-color);
    cursor: pointer;
}
.top__arrow path{
    stroke: var(--main-text-color);
    stroke-width: 2px;
}


.bottom{
    transition: all .2s ease-in-out;
    max-height: 0;
}

.bottom-collapsed{
    transition: all .2s ease-in-out;
    max-height: 100%;
}

.bottomContainer{
    transition: all .2s ease-in-out;
    transform: translateY(-100%);
    visibility: hidden;
    max-height: 0;
    opacity: 0;
}

.bottomContainer-collapsed{
    transition: all .2s ease-in-out;
    transform: translateY(0);
    visibility: visible;
    max-height: 100%;
    opacity: 1;
    z-index: -1;
}