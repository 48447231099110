.checkEmail__wrapper {
    text-align: center;
}

.checkEmail__content {
    /*max-width: 320px;*/
    margin: 0 auto 20px;
}

.checkEmail__btn {
    font-size: 24px;
    line-height: 35px;
    text-decoration-line: underline;
    color: var(--white-color);
    cursor: pointer;
}

.checkEmail__btn:active {
    color: #1890ff;
}
