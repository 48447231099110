.scheduler {
    /* background: var(--gray-border-color);; */
    font-family: Montserrat, sans-serif;
    overflow: hidden;
    box-sizing: border-box;
}

.scheduler-simplebar .simplebar-mask {
    width: auto !important;
    height: calc(100vh - 45px);

    box-sizing: border-box;
}

.scheduler__inner {
    height: calc(100vh - 45px);

    display: grid;
    grid-template-columns: auto 1fr;
}

.spinContainer {
    height: 80vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.scheduler-spin{
    width: 100%;
    height: calc(100vh - 45px);
    display: flex;
    justify-content: center;
    align-items: center;
    background: #e9eff3;
}
